import React, { useEffect, useRef, useState } from 'react'

const Form_Accordion_Result = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const [ requirement_CHOg, setRequirement_CHOg ] = useState(-1);
  const [ requirement_PROg, setRequirement_PROg ] = useState(-1);
  const [ requirement_FATg, setRequirement_FATg ] = useState(-1);

  const [ intake_CHO_percent, setIntake_CHO_percent ] = useState(-1);
  const [ intake_PRO_percent, setIntake_PRO_percent ] = useState(-1);
  const [ intake_FAT_percent, setIntake_FAT_percent ] = useState(-1);

  const [ gap_energy, setGap_energy ] = useState(-1);
  const [ gap_CHO, setGap_CHO ] = useState(-1);
  const [ gap_PRO, setGap_PRO ] = useState(-1);
  const [ gap_FAT, setGap_FAT ] = useState(-1);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
  }, [isOpen]);

  useEffect(() => {
    const energy_requirement = props.req_energy;

    if(energy_requirement === -1) {
      setRequirement_CHOg(-1);
      setRequirement_PROg(-1);
      setRequirement_FATg(-1);

      setIntake_CHO_percent(-1);
      setIntake_PRO_percent(-1);
      setIntake_FAT_percent(-1);

      setGap_energy(-1);
      setGap_CHO(-1);
      setGap_PRO(-1);
      setGap_FAT(-1);
      return;
    }

    setRequirement_CHOg(get_requirement_in_gram(energy_requirement, props.req_CHO, 4));
    setRequirement_PROg(get_requirement_in_gram(energy_requirement, props.req_PRO, 4));
    setRequirement_FATg(get_requirement_in_gram(energy_requirement, props.req_FAT, 9));
  }, [props]);

  function get_requirement_in_gram(energy_requirement, percent, energy_density) {
    let result = (energy_requirement * (percent/100)) / energy_density;
    return (Math.round(result * 10)) / 10;
  }

  return (
    <div className='my-4'>
      <button type='button' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
          <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลผู้ป่วย</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-8 pb-16 bg-white'>
          <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start mb-8'>ผลลัพธ์พลังงานและสารอาหาร</h3>
          
          <table className="table-auto w-full h-fit border border-gray-300 border-separate rounded-md py-4">
            <thead>
              <tr className='w-full h-fit border border-gray-300 border-separate'>
                <th>สารอาหาร</th>
                <th>ความต้องการ</th>
                <th>การบริโภค</th>
                <th>ส่วนต่าง</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='pl-4'>พลังงาน</td>
                <td className='text-center'>{(props.req_energy === -1) ? 'N/A' : props.req_energy + ' kcal'}</td>
                <td className='text-center'>{(props.intake_energy === -1) ? 'N/A' : props.intake_energy + ' kcal'}</td>
                <td className='text-center'>1972</td>
              </tr>
              <tr>
                <td className='pl-4'>คาร์โบไฮเดรต</td>
                <td className='text-center'>{(requirement_CHOg === -1) ? 'N/A (' : requirement_CHOg + ' g ('} {props.req_CHO} % {' )'}</td>
                <td className='text-center'>1972</td>
                <td className='text-center'>1972</td>
              </tr>
              <tr>
                <td className='pl-4'>โปรตีน</td>
                <td className='text-center'>{(requirement_PROg === -1) ? 'N/A (' : requirement_PROg + ' g ('} {props.req_PRO} % {' )'}</td>
                <td className='text-center'>1975</td>
                <td className='text-center'>1975</td>
              </tr>
              <tr>
                <td className='pl-4'>ไขมัน</td>
                <td className='text-center'>{(requirement_FATg === -1) ? 'N/A (' : requirement_FATg + ' g ('} {props.req_FAT} % {' )'}</td>
                <td className='text-center'>1975</td>
                <td className='text-center'>1975</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Form_Accordion_Result