import React, { useEffect, useState } from 'react';
import ModalWaiting from '../../Modal/ModalWaiting/ModalWaiting';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";

const Modal_record_preview = ({isShow, detail, handle_cancel, handle_submit}) => {
    useEffect(() => {
        console.log(detail);
    }, [detail])

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-50 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                <div className='w-8/12 h-5/6 bg-white drop-shadow-md rounded-md py-28 overflow-y-scroll'>
                    <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center mt-6 mb-4'>ข้อมูลการประเมิน OPD</h3>

                    <div className='mx-28'>
                        <p className='notoser-bold text-web-black text-lg'>ระบุชื่อ-นามสกุลของผู้ป่วย</p>
                        <p className='notoser-reg text-web-black my-2'>{detail.ชื่อนามสกุล}</p>
                    </div>

                    <div className='relative ml-28 w-full h-28 rounded-md my-12 hidden'>
                        <img src='https://i.pinimg.com/originals/c4/06/65/c40665c475735cf7436a12b9995998b2.jpg' className='w-full h-full object-cover rounded-md'/>

                        <div className='absolute bottom-0 left-0 w-full h-fit z-10'>
                            <h3 className='notoser-bold text-xl sm:text-3xl text-white mb-4 ml-4'>กลุ่มโรค CCPC</h3>
                        </div>

                        <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-t from-black/70 rounded-md'></div>
                    </div>

                    <div className='mx-28'>
                        <div className='w-full h-fit flex gap-5 mt-12 mb-6'>
                            <div className='w-4 h-auto bg-orange-primary'></div>
                            <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center pt-3'>กลุ่มโรค CCPC</h3>
                        </div>

                        <p className='notoser-bold text-web-black text-lg'>ระบุชื่อ-นามสกุลของผู้ป่วย</p>
                        <p className='notoser-reg text-web-black my-2'>{detail.ชื่อนามสกุล}</p>
                    </div>

                    <div className='mx-28'>
                        <div className='w-full h-fit flex gap-5 mt-12 mb-6'>
                            <div className='w-4 h-auto bg-orange-primary'></div>
                            <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center pt-3'>กลุ่มโรคเบาหวาน</h3>
                        </div>

                        <p className='notoser-bold text-web-black text-lg'>ระบุชื่อ-นามสกุลของผู้ป่วย</p>
                        <p className='notoser-reg text-web-black my-2'>{detail.ชื่อนามสกุล}</p>
                    </div>

                    <div className='mx-28'>
                        <div className='w-full h-fit flex gap-5 mt-12 mb-6'>
                            <div className='w-4 h-auto bg-orange-primary'></div>
                            <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center pt-3'>กลุ่มโรคอื่นๆ</h3>
                        </div>

                        <p className='notoser-bold text-web-black text-lg'>ระบุชื่อ-นามสกุลของผู้ป่วย</p>
                        <p className='notoser-reg text-web-black my-2'>{detail.ชื่อนามสกุล}</p>
                    </div>

                    <div className='mx-28 mt-10 grid grid-cols-2 gap-3'>
                    <button className='notoser-med w-full h-full bg-gray-300 hover:bg-gray-500 py-3 rounded-md' onClick={handle_cancel}>ยกเลิก</button>
                        <button className='w-full h-fit px-6 py-3 rounded-md bg-orange-primary notoser-med text-web-dark-blue hover:bg-web-dark-blue hover:text-white' onClick={handle_submit}>แก้ไขข้อมูล</button>
                    </div>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40 ' + (isShow ? 'block' : 'hidden')}></div>
        </>
    )
}

const Form_OPD_dashboard = () => {
    const [ loading_screen, setLoading_screen ] = useState(false);
    const [ OPD_list, setOPD_list ] = useState([]);
    const [ show_modal_preview, setShow_modal_preview ] = useState(false);
    const [ detail_modal_preview, setDetail_modal_preview ] = useState({});

    const { name } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let abortcontroller = new AbortController();

        if(name === null || name === undefined) {
            navigate("/staff", { replace: true });
        }
        else {
            setLoading_screen(true);

            axios.get("https://script.google.com/macros/s/AKfycbwEGz8ydMQGp_j2qJpCLlsOXavZSNXH0bNIDbYM-zBX1BhiTgLqEBpoHSdHB5zGFaZSHg/exec", { signal: abortcontroller.signal })
            .then((response) => {
                console.log(response.data);

                if (response.data.length > 0) {
                    setOPD_list(response.data);
                    setFiltered_record_OPD(response.data);
                    setLoading_screen(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        }

        return () => abortcontroller.abort();
    }, []);

    function Datatable_filter_input(key, filter_function) {
        return (
          <div className='bg-white border border-gray-200 focus:border-pink-bold focus:outline-pink-bold rounded-md w-full h-10 px-4 pt-1 my-2'>
            <input className='w-full h-full outline-none' 
            placeholder={'ค้นหาด้วย ' + key} 
            onChange={(e) => filter_function(e, key)}/>
          </div>
        )
    }

    const [ filterd_OPD_option, setFiltered_OPD_option ] = useState({ 
        วันที่ประเมิน: '',
        ชื่อนามสกุล: '',
        HN: '',
        ผ่านเกณฑ์_AS: '',
        ผ่านเกณฑ์_THT: '',
        SOC_ผ่าน: '',
        LDL_ผ่าน: '',
    });

    const [ filterd_record_OPD, setFiltered_record_OPD ] = useState([]);

    const handle_datatable_filter_changed  = async (e, key) => {
        await handle_filter_option_changed(e, key);
        await handle_filter();
    
        function handle_filter_option_changed(e, key) {
          return new Promise((resolve) => {
            let filter_option = filterd_OPD_option;
            filter_option[key] = e.target.value;
            setFiltered_OPD_option(filter_option);
            resolve();
          });
        }
    
        function handle_filter() {
          return new Promise((resolve) => {
            let results = OPD_list.filter(value => {
                return (
                    value["วันที่ประเมิน"].toString().includes(filterd_OPD_option["วันที่ประเมิน"]) &&
                    value["ชื่อนามสกุล"].toString().includes(filterd_OPD_option["ชื่อนามสกุล"]) &&
                    value["HN"].toString().includes(filterd_OPD_option["HN"]) &&
                    value["ผ่านเกณฑ์_AS"].toString().includes(filterd_OPD_option["ผ่านเกณฑ์_AS"]) &&
                    value["ผ่านเกณฑ์_THT"].toString().includes(filterd_OPD_option["ผ่านเกณฑ์_THT"]) &&
                    value["SOC_ผ่าน"].toString().includes(filterd_OPD_option["SOC_ผ่าน"]) &&
                    value["LDL_ผ่าน"].toString().includes(filterd_OPD_option["LDL_ผ่าน"])
                );
            });
    
            setFiltered_record_OPD(results);
            resolve();
          });
        }
    }

    return (
        <>
            <div className='relative staff_login_container'>
                <div className='absolute left-0 top-0 w-screen h-screen overflow-y-auto z-10'>
                    <div className='mx-8 my-8'>
                        <div className='w-full h-fit px-8 md:px-12 py-14 bg-white rounded-lg shadow-md drop-shadow-md z-10'>
                            <div className='w-full h-fit border border-gray-300 px-6 py-6 rounded-md'>
                                <h3 className='notoser-bold text-xl sm:text-3xl text-orange-primary text-center mt-6 mb-4'>แบบฟอร์มบันทึกสถิติ OPD</h3>
                                <p className='notoser-med text-web-black text-center'>ผู้ประเมิน: {name}</p>

                                <div className='w-full h-fit block sm:flex flex-row sm:flex-row-reverse justify-center gap-5 mt-8 mb-4'>
                                    <Link to={'/staff/form/opd/add/' + name}>
                                        <button className='w-full sm:w-40 h-fit border border-gray-300 rounded-full py-1.5 bg-white hover:bg-zinc-700 hover:text-white transition-color duration-300'>เพิ่มข้อมูลใหม่</button>
                                    </Link>

                                    <Link to={-1}>
                                        <button className='w-full sm:w-40 h-fit border border-gray-300 rounded-full py-1.5 bg-white hover:bg-zinc-700 hover:text-white transition-color duration-300 mt-2 sm:mt-0'>ย้อนกลับ</button>
                                    </Link>
                                </div>
                            </div>

                            <h3 className='notoser-bold text-3xl text-orange-primary mt-10 mb-4'>การประเมินย้อนหลัง</h3>

                            <DataTable 
                            value={filterd_record_OPD} 
                            stripedRows 
                            paginator rows={20}
                            className='w-full h-fit py-6 overflow-y-auto cursor-pointer'
                            globalFilterFields={['วันที่บันทึกผล', 'ผู้ประเมิน', 'โรงอาหาร', 'ชื่อร้านค้า']}
                            filterDisplay="row"
                            onRowClick={(e) => {
                                setDetail_modal_preview({...e.data});
                                setShow_modal_preview(true);
                            }}>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('วันที่ประเมิน', handle_datatable_filter_changed)} field="วันที่ประเมิน" header="วันที่ประเมิน" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ชื่อนามสกุล', handle_datatable_filter_changed)} field="ชื่อนามสกุล" header="ชื่อนามสกุล" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('HN', handle_datatable_filter_changed)} field="HN" header="HN" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ผ่านเกณฑ์_AS', handle_datatable_filter_changed)} field="ผ่านเกณฑ์_AS" header="ผ่านเกณฑ์_AS" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('ผ่านเกณฑ์_THT', handle_datatable_filter_changed)} field="ผ่านเกณฑ์_THT" header="ผ่านเกณฑ์_THT" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('SOC_ผ่าน', handle_datatable_filter_changed)} field="SOC_ผ่าน" header="Stage of Change" sortable></Column>
                                <Column className='h-10 translate-y-1' headerClassName={'notoser-bold py-2 pt-3 pb-2'} filter filterElement={Datatable_filter_input('LDL_ผ่าน', handle_datatable_filter_changed)} field="LDL_ผ่าน" header="LDL" sortable></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
            </div>

            { /* Backdrop screen for loading */ }
            <ModalWaiting isShow={loading_screen}/>
            <Modal_record_preview isShow={show_modal_preview} detail={detail_modal_preview} 
            handle_cancel={() => setShow_modal_preview(false)}
            handle_submit={() => {
                alert("test");
            }}/>
        </>
    )
}

export default Form_OPD_dashboard