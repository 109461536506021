import React, { useEffect, useRef, useState } from 'react';
import '../../../../pages/Staff_login/staff_login.css';
import Form_Accordion_Assessment from './Form_Accordion_Assessment';
import Form_Accordion_Result from './Form_Accordion_Result';
import Form_Accordion_DietaryNote from './Form_Accordion_DietaryNote';

const Form_Submit_Button = () => {
  return (
    <div className='w-full h-fit px-8 md:px-12 pt-2 pb-16 bg-white rounded-md'>
      <div className='w-full h-fit grid grid-cols-2 gap-5'>
        <div className='w-full h-fit'>
          <p className='notoser-reg text-web-black mt-16 mb-4'>กรุณาระบุวันที่บริโภค</p>
          <input type='date' placeholder='กรุณาระบุวันที่บริโภค'
          className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1 cursor-pointer'></input>
        </div>

        <div className='w-full h-fit'>
          <p className='notoser-reg text-web-black mt-16 mb-4'>กรุณาระบุประเภทของวันที่บริโภค</p>

          <select className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4'>
            <option value={'N/A'} selected={true}>กรุณาระบุประเภทของวันที่บริโภค...</option>
            <option value={'วันธรรมดา'}>วันธรรมดา</option>
            <option value={'วันหยุด'}>วันหยุด</option>
          </select>
        </div>
      </div>

      <p className='notoser-reg text-web-black text-center pt-8 mb-4'>กรุณากดปุ่ม ส่งผลลัพธ์ เพื่อส่งข้อมูลเข้าสู่ระบบ</p>

      <div className='w-full h-fit flex justify-center'>
        <button className='notoser-semi w-60 h-fit bg-orange-primary text-white px-10 py-4 rounded-md hover:bg-orange-bold'>ส่งข้อมูล</button>
      </div>
    </div>
  )
}

const Form_NutritionIntake = () => {
  const [ energy_requirement, setEnergy_requirement ] = useState(-1);
  const [ CHO_requirement, setCHO_requirement ] = useState(55);
  const [ PRO_requirement, setPRO_requirement ] = useState(15);
  const [ FAT_requirement, setFAT_requirement ] = useState(30);
  const [ energy_intake, setEnergy_intake ] = useState(-1);
  const [ CHO_intake, setCHO_intake ] = useState(-1);
  const [ PRO_intake, setPRO_intake ] = useState(-1);
  const [ FAT_intake, setFAT_intake ] = useState(-1);

  return (
    <>
      <div className='relative staff_login_container'>
        <div className='absolute left-0 top-0 w-screen h-screen z-10 block md:flex justify-end gap-5'>
          <div className='w-screen h-screen overflow-y-auto ml-6 mr-6'>
            <div className='w-full h-fit mt-2 mb-10'>
              <form id="google-sheet" name="google-sheet" className='w-full h-fit'
              onSubmit={(e) => e.preventDefault()}>

                <Form_Accordion_Assessment handle_update={(energy, CHO, PRO, FAT) => {
                  setEnergy_requirement(energy);
                  setCHO_requirement(CHO);
                  setPRO_requirement(PRO);
                  setFAT_requirement(FAT);
                }}/>
                
                <div className='my-4'></div>

                <Form_Accordion_Result
                req_energy={energy_requirement}
                req_CHO={CHO_requirement}
                req_PRO={PRO_requirement}
                req_FAT={FAT_requirement}
                intake_energy={energy_intake}
                intake_CHO={CHO_intake}
                intake_PRO={PRO_intake}
                intake_FAT={FAT_intake}/>

                <div className='my-4'></div>

                <Form_Accordion_DietaryNote/>

                <div className='my-4'></div>

                <Form_Submit_Button/>
              </form>
            </div>
          </div>
        </div>

        <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
      </div>
    </>
  )
}

export default Form_NutritionIntake