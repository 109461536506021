import React, { useEffect, useState } from 'react';
import './staff_login.css';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/logo.jpg';
import smart_nutrition_counseling from '../../assets/logo/smart_nutrition_counseling.svg';

import ModalWaiting from '../Components/Modal/ModalWaiting/ModalWaiting';
import axios from 'axios';

import UilEyeOpen from '@iconscout/react-unicons/icons/uil-eye';
import UilEyeClose from '@iconscout/react-unicons/icons/uil-eye-slash';

const Staff_login = () => {
  const [ loading_screen, setLoading_screen ] = useState(true);
  const [ login_failed, setLogin_failed ] = useState(false);
  const [ active_submit, setActive_submit ] = useState(false);
  const [ username, setUsername ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ staff_list, setStaff_list ] = useState([]);
  const [ hide_password, setHidePassword ] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    let abortcontroller = new AbortController();

    setLoading_screen(true);

    axios.get("https://script.google.com/macros/s/AKfycbznGcrqNx0oHYHJz7ths4xec7CG_D29zxeeCHN_Gf242aQN4mEgZ8-kMeqxnUnqfDOw3A/exec", { signal: abortcontroller.signal })
    .then((response) => {
        if (response.data.length > 0) {
          setStaff_list([]);
          setStaff_list(response.data);
          setLoading_screen(false);
        }
    })
    .catch((error) => {
        console.log(error);
    });

    return () => abortcontroller.abort();
  }, []);

  const on_change_username = () => {
    setUsername(document.getElementById('staff_login_username').value);
    activate_submit_button();
  }

  const on_change_password = () => {
    setPassword(document.getElementById('staff_login_password').value);
    activate_submit_button();
  }

  function activate_submit_button() {
    setActive_submit(false);

    if(document.getElementById('staff_login_username').value !== '' && document.getElementById('staff_login_password').value !== '') {
      setActive_submit(true);
    }
  }

  const submit_form = async () => {
    if(active_submit) {
      setLoading_screen(true);
      setLogin_failed(false);

      const login_success = await check_accounts();
      await redirect_page(login_success);

      function check_accounts() {
        return new Promise((resolve) => {
          let result = {
            success: false,
            name: undefined,
          };
    
          staff_list.map(x => {
            const ref_username = x.username.toString().trim();
            const ref_password = x.password.toString().trim();
    
            const user_username = username.toString().trim();
            const user_password = password.toString().trim();
    
            if(user_username === ref_username && user_password === ref_password) {
              result.success = true;
              result.name = x.name;
            }
          })
    
          resolve(result);
        })
      }

      function redirect_page(login_info) {
        return new Promise((resolve) => {
          if(login_info.success) {
            setLoading_screen(false);
            navigate("/staff/dashboard/" + login_info.name, { replace: true });
          }
          else {
            setLoading_screen(false);
            setLogin_failed(true);
          }
    
          resolve();
        })
      }
    }
  }

  return (
    <>
      <div className='relative staff_login_container'>
        { /* Content */ }
        <div className='relative w-full h-full flex justify-center items-center'>
          <div className='w-11/12 sm:w-2/3 h-fit px-12 py-14 bg-white rounded-lg shadow-md drop-shadow-md z-10'>
            <div className='w-full h-fit flex justify-center'>
              <img src={logo} className='w-auto h-20' />
            </div>

            <h3 className='notoser-semi text-2xl text-center text-web-brown mt-4 mb-2'>ลงชื่อเข้าใช้งาน</h3>
            <div className='w-full h-fit flex justify-center'>
              <img src={smart_nutrition_counseling} className='w-auto h-10' />
            </div>

            <p className='notoser-reg text-base text-web-brown'>ชื่อบัญชี</p>
            <input type='text' className='w-full border border-gray-300 pt-3 pb-2 pl-3 pr-3 rounded-md my-3 outline-pink-bold' placeholder='โปรดระบุชื่อบัญชี' id='staff_login_username' onChange={on_change_username}/>

            <p className='notoser-reg text-base text-web-brown mt-4'>รหัสผ่าน</p>
            <div className='relative w-full h-fit my-3'>
              <input type={hide_password ? 'password' : 'text'} className='w-full border border-gray-300 pt-3 pb-2 pl-3 pr-3 rounded-md outline-pink-bold' placeholder='โปรดระบุรหัสผ่าน' id='staff_login_password' onChange={on_change_password}/>
              <div className='absolute top-0 right-0 w-14 h-full flex justify-center items-center'>
                <UilEyeOpen className={'cursor-pointer ' + (hide_password ? 'block' : 'hidden')} size="25" color='#C0C0C0' onClick={() => setHidePassword(false)}/>
                <UilEyeClose className={'cursor-pointer ' + (hide_password ? 'hidden' : 'block')} size="25" color='#C0C0C0' onClick={() => setHidePassword(true)}/>
              </div>
            </div>

            <div className='grid grid-cols-2 gap-5 sm:gap-10 my-10'>
              <Link to={"/"} className='w-full h-fit'>
                <button className='w-full h-fit bg-gray-300 hover:bg-gray-500 py-4 rounded-md notoser-reg'>กลับหน้าหลัก</button>
              </Link>
              
              <button className={'w-full h-fit py-4 rounded-md notoser-reg ' + (active_submit ? 'bg-orange-primary hover:bg-orange-bold text-white' : 'bg-gray-300 text-gray-500 cursor-not-allowed')} onClick={submit_form}>ยืนยัน</button>
            </div>
          </div>
        </div>

        <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
      </div>
        
      { /* Backdrop screen for loading */ }
      <ModalWaiting isShow={loading_screen}/>
    </>
  )
}

export default Staff_login