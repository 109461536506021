import React, { useEffect, useRef } from 'react';

const Form_NutritionIntake_Nutrient_Input = ({nutrient, percent, current_value, handle_onchange, handle_calculate}) => {
    const ref = useRef(null);

    useEffect(() => {
        if(ref.current) {
            ref.current.value = percent;
        }
    }, [percent]);

    return (
        <input ref={ref} type='number' placeholder={'โปรดระบุสัดส่วน' + nutrient}
        onFocus={(e) => e.target.value = ''} 
        onBlur={(e) => {
            if(e.target.value === '') {
                if(ref.current) {
                    ref.current.value = percent;
                }
            }
            else {
                if(current_value !== -1) {
                    handle_calculate(e.target.value);
                }
            }
        }}
        onChange={(e) => handle_onchange(e.target.value)} 
        onKeyUp={(e) => {
            if(e.key === 'Enter') {
                if(e.target.value === '') {
                    if(ref.current) {
                        ref.current.value = percent;
                    }
                }
                else {
                    if(current_value !== -1) {
                        handle_calculate(e.target.value);
                    }
                }

                if(ref.current) {
                    ref.current.blur();
                }
            }
        }}
        className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary w-full h-10 px-4 pt-1'></input>
    )
}

export default Form_NutritionIntake_Nutrient_Input