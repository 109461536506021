import React, { useEffect, useRef, useState } from 'react';

const Adding_food_panel = ({handle_height_change}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if(contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }

    handle_height_change(contentRef.current.scrollHeight);
  }, [isOpen]);

  const color_dawn = '#c69fbb';
  const color_morning = '#d9bfd1';
  const color_late_morning = '#B5D4E3';
  const color_noon = '#C9E5F6';
  const color_afternoon = '#F7E8A6';
  const color_evening = '#FFC09F';
  const color_night = '#1B2A41';

  const color_light_text_dawn = '#503047';
  const color_light_text_morning = '#503047';
  const color_light_text_late_morning = '#1c3a4a';
  const color_light_text_noon = '#1c3a4a';
  const color_light_text_afternoon = '#2f2804';
  const color_light_text_evening = '#331200';
  const color_light_text_night = '#223644';

  const color_light_bg_dawn = '#d9bfd2';
  const color_light_bg_morning = '#e2cfdd';
  const color_light_bg_late_morning = '#dae9f1';
  const color_light_bg_noon = '#e9f4fb';
  const color_light_bg_afternoon = '#fbf3d0';
  const color_light_bg_evening = '#ffdecc';
  const color_light_bg_night = '#8aabc5';

  const color_light_text_dawn_disable = '#8d898c';
  const color_light_text_morning_disable = '#726f71';
  const color_light_text_late_morning_disable = '#5a656b';
  const color_light_text_noon_disable = '#6e7d85';
  const color_light_text_afternoon_disable = '#615e4f';
  const color_light_text_evening_disable = '#665851';
  const color_light_text_night_disable = '#525d64';

  const color_light_bg_dawn_disable = '#d6cbd3';
  const color_light_bg_morning_disable = '#d6cbd3';
  const color_light_bg_late_morning_disable = '#dfdede';
  const color_light_bg_noon_disable = '#e7e7e7';
  const color_light_bg_afternoon_disable = '#e0d4cd';
  const color_light_bg_evening_disable = '#d1c2c1';
  const color_light_bg_night_disable = '#a4b6c4';

  const foodExchange = [
    {category: "ข้าวแป้ง", servingUnit: "ทัพพี", weight: 55, energy: 80, carbohydrates: 18, protein: 2, fat: 0, sodium: 0},
    {category: "เนื้อสัตว์ไขมันต่ำมาก", servingUnit: "ช้อนโตีะ", weight: 15, energy: 17.5, carbohydrates: 0, protein: 3.5, fat: 0.5, sodium: 0},
    {category: "เนื้อสัตว์ไขมันต่ำ", servingUnit: "ช้อนโตีะ", weight: 15, energy: 27.5, carbohydrates: 0, protein: 3.5, fat: 1.5, sodium: 0},
    {category: "เนื้อสัตว์ไขมันปานกลาง", servingUnit: "ช้อนโตีะ", weight: 15, energy: 37.5, carbohydrates: 0, protein: 3.5, fat: 2.5, sodium: 0},
    {category: "เนื้อสัตว์ไขมันสูง", servingUnit: "ช้อนโตีะ", weight: 15, energy: 50, carbohydrates: 0, protein: 3.5, fat: 4, sodium: 0},
    {category: "ผัก ก", servingUnit: "ทัพพี", weight: 50, energy: 0, carbohydrates: 0, protein: 0, fat: 0, sodium: 0},
    {category: "ผัก ข", servingUnit: "ทัพพี", weight: 50, energy: 25, carbohydrates: 5, protein: 2, fat: 0, sodium: 0},
    {category: "ผลไม้", servingUnit: "ส่วน", weight: "-", energy: 60, carbohydrates: 15, protein: 0, fat: 0, sodium: 0},
    {category: "นมปกติ", servingUnit: "แก้ว", weight: 240, energy: 150, carbohydrates: 12, protein: 8, fat: 8, sodium: 0},
    {category: "นมพร่องมันเนย", servingUnit: "แก้ว", weight: 240, energy: 120, carbohydrates: 12, protein: 8, fat: 5, sodium: 0},
    {category: "นมไม่มีไขมัน", servingUnit: "แก้ว", weight: 240, energy: 90, carbohydrates: 12, protein: 8, fat: 0, sodium: 0},
    {category: "นมระเหย", servingUnit: "ครึ่งถ้วย", weight: 120, energy: 150, carbohydrates: 12, protein: 8, fat: 8, sodium: 0},
    {category: "นมผง", servingUnit: "ส่วน (4 ช้อนตวง)", weight: 30, energy: 150, carbohydrates: 12, protein: 8, fat: 8, sodium: 0},
    {category: "นมผงไม่มีไขมัน", servingUnit: "ส่วน (4 ช้อนตวง)", weight: 30, energy: 90, carbohydrates: 12, protein: 8, fat: 0, sodium: 0},
    {category: "ไขมัน", servingUnit: "ช้อนชา", weight: 5, energy: 45, carbohydrates: 0, protein: 0, fat: 5, sodium: 0}
  ];

  return (
    <div className='my-4 border border-gray-300 rounded-md'>
      <button type='button' className={"w-full px-8 py-4 text-left border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md border-b' : 'rounded-md border-none')} onClick={() => setIsOpen(!isOpen)}>
        <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>กล่องบันทึกข้อมูล</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ height: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-6 pb-10 bg-white'>
          <div className='w-full h-fit grid grid-cols-6 gap-3'>
            <div className='w-full h-fit col-span-4'>
              <p className='notoser-reg text-web-black my-4'>1. โปรดระบุรายการอาหาร<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
              <input type='text' placeholder='โปรดระบุรายการอาหาร' className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>
            </div>

            <div className='w-full h-full flex items-end'>
              <select className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'>
                <option value={'N/A'}>โปรดเลือก...</option>
                <option value={'ซื้อ'}>ซื้อ</option>
                <option value={'ทำเอง'}>ทำเอง</option>
              </select>
            </div>

            <div className='w-full h-full flex items-end'>
              <select className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'>
                <option value={'มื้อว่างเช้ามืด'}>มื้อว่างเช้ามืด</option>
                <option value={'มื้อเช้า'} selected>มื้อเช้า</option>
                <option value={'มื้อว่างเช้า'}>มื้อว่างเช้า</option>
                <option value={'มื้อกลางวัน'}>มื้อกลางวัน</option>
                <option value={'มื้อว่างบ่าย'}>มื้อว่างบ่าย</option>
                <option value={'มื้อเย็น'}>มื้อเย็น</option>
                <option value={'มื้อดึก'}>มื้อดึก</option>
              </select>
            </div>
          </div>

          <div className='w-full h-fit grid grid-cols-6 gap-3'>
            <div className='w-full h-fit col-span-5'>
              <p className='notoser-reg text-web-black my-4'>2. โปรดระบุส่วนประกอบ<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
              <input type='text' placeholder='โปรดระบุส่วนประกอบ' className='bg-white border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-1'></input>
            </div>

            <div className='w-full h-full flex items-end'>
              <select className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'>
                <option value={'N/A'}>โปรดเลือก2...</option>

                {
                  foodExchange.map(x => {
                    <option value={x.category}>{x.category}</option>
                  })
                }
              </select>
            </div>
          </div>

          <p className='notoser-reg text-web-black my-4'>3. โปรดระบุปริมาณ<sup className='notoser-reg' style={{fontSize: 10}}><i>require</i></sup></p>
        </div>
      </div>
    </div>
  )
}

const Form_Accordion_DietaryNote = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  const [ add_panel_height, setAdd_panel_height ] = useState(0);

  useEffect(() => {
    if(contentRef.current) {
      setHeight(isOpen ? `${contentRef.current.scrollHeight + add_panel_height}px` : '0px');
    }
  }, [isOpen, add_panel_height]);
  
  return (
    <div className='my-4'>
      <button type='button' id='accordion_other_header' className={"w-full px-8 py-4 text-left border-b border-gray-300 flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
        <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>ข้อมูลการบริโภคอาหาร</h3>
      </button>

      <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
        <div className='w-full h-fit px-8 md:px-12 pt-10 pb-14 bg-white'>
          <p>table</p>

          <Adding_food_panel
          handle_height_change={(new_height) => {
            setAdd_panel_height(new_height);
          }}/>
        </div>
      </div>
    </div>
  )
}

export default Form_Accordion_DietaryNote