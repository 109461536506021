import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Staff_form_cover = ({cover_url, title, url_destination}) => {
    const [ isHover, setIsHover ] = useState(false);

    return (
        <Link to={url_destination} className='catalog_card w-full lg:w-60 border border-gray-300 rounded-lg' onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            <div className='w-full h-80 overflow-hidden rounded-t-lg'>
                <img className={'w-full h-full object-cover rounded-t-lg transition-transform duration-500 ' + (isHover ? "scale-125" : "")} src={cover_url}></img>
            </div>

            <div className='my-3 mx-5'>
                <p className={'notoser-med text-center py-2.5 line-clamp-3 overflow-hidden ' + (isHover ? "text-pink-bold underline" : "text-chula-brown no-underline")}>{title}</p>
            </div>
        </Link>
    )
}

export default Staff_form_cover