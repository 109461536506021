import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

const ModalBehavScore = ({isShow, onCancel, onSubmit, items_oral, items_ons, day}) => {
    const [ is_oral, setIs_oral ] = useState(false);
    const [ oral_height, setOral_height ] = useState(false);
    const ref_oral = useRef(null);
    const [ active_oral, setActive_oral ] = useState(true);

    const [ is_ons, setIs_ons ] = useState(false);
    const [ ons_height, setONS_height ] = useState(false);
    const ref_ons = useRef(null);
    const [ active_ons, setActive_ons ] = useState(true);

    const ref_select_oral_understand = useRef([]);
    const ref_select_oral_understand_prev = useRef([]);
    const ref_select_oral_practice = useRef([]);
    const ref_select_oral_practice_prev = useRef([]);
    const ref_select_ons_understand = useRef([]);
    const ref_select_ons_understand_prev = useRef([]);
    const ref_select_ons_practice = useRef([]);
    const ref_select_ons_practice_prev = useRef([]);

    const [ backup_oral_understand, setBackup_oral_understand ] = useState([]);
    const [ backup_oral_understand_prev, setBackup_oral_understand_prev ] = useState([]);
    const [ backup_oral_practice, setBackup_oral_practice ] = useState([]);
    const [ backup_oral_practice_prev, setBackup_oral_practice_prev ] = useState([]);
    const [ backup_ons_understand, setBackup_ons_understand ] = useState([]);
    const [ backup_ons_understand_prev, setBackup_ons_understand_prev ] = useState([]);
    const [ backup_ons_practice, setBackup_ons_practice ] = useState([]);
    const [ backup_ons_practice_prev, setBackup_ons_practice_prev ] = useState([]);

    const [ score_oral_understand, setScore_oral_understand ] = useState(0);
    const [ score_oral_understand_prev, setScore_oral_understand_prev ] = useState(0);
    const [ score_oral_practice, setScore_oral_practice ] = useState(0);
    const [ score_oral_practice_prev, setScore_oral_practice_prev ] = useState(0);
    const [ score_ons_understand, setScore_ons_understand ] = useState(0);
    const [ score_ons_understand_prev, setScore_ons_understand_prev ] = useState(0);
    const [ score_ons_practice, setScore_ons_practice ] = useState(0);
    const [ score_ons_practice_prev, setScore_ons_practice_prev ] = useState(0);

    useEffect(() => {
        if(isShow) {
            let array_oral_understand = [];
            if(ref_select_oral_understand.current) {
                ref_select_oral_understand.current.forEach(x => {
                    array_oral_understand.push(x.value);
                });
            }
            setBackup_oral_understand(array_oral_understand);

            let array_oral_understand_prev = [];
            if (ref_select_oral_understand_prev.current) {
                ref_select_oral_understand_prev.current.forEach(x => {
                    array_oral_understand_prev.push(x.value);
                });
            }
            setBackup_oral_understand_prev(array_oral_understand_prev);

            let array_oral_practice = [];
            if (ref_select_oral_practice.current) {
                ref_select_oral_practice.current.forEach(x => {
                    array_oral_practice.push(x.value);
                });
            }
            setBackup_oral_practice(array_oral_practice);

            let array_oral_practice_prev = [];
            if (ref_select_oral_practice_prev.current) {
                ref_select_oral_practice_prev.current.forEach(x => {
                    array_oral_practice_prev.push(x.value);
                });
            }
            setBackup_oral_practice_prev(array_oral_practice_prev);

            let array_ons_understand = [];
            if (ref_select_ons_understand.current) {
                ref_select_ons_understand.current.forEach(x => {
                    array_ons_understand.push(x.value);
                });
            }
            setBackup_ons_understand(array_ons_understand);

            let array_ons_understand_prev = [];
            if (ref_select_ons_understand_prev.current) {
                ref_select_ons_understand_prev.current.forEach(x => {
                    array_ons_understand_prev.push(x.value);
                });
            }
            setBackup_ons_understand_prev(array_ons_understand_prev);

            let array_ons_practice = [];
            if (ref_select_ons_practice.current) {
                ref_select_ons_practice.current.forEach(x => {
                    array_ons_practice.push(x.value);
                });
            }
            setBackup_ons_practice(array_ons_practice);

            let array_ons_practice_prev = [];
            if (ref_select_ons_practice_prev.current) {
                ref_select_ons_practice_prev.current.forEach(x => {
                    array_ons_practice_prev.push(x.value);
                });
            }
            setBackup_ons_practice_prev(array_ons_practice_prev);
        }
    }, [isShow])

    useEffect(() => {
        if(ref_oral.current) {
            setOral_height(is_oral ? `${ref_oral.current.scrollHeight}px` : '0px');
        }

        if(ref_ons.current) {
            setONS_height(is_ons ? `${ref_ons.current.scrollHeight}px` : '0px');
        }
    }, [is_oral, is_ons]);

    function reset_oral() {
        ref_select_oral_practice.current?.forEach(x => {
            x.selectedIndex = 0;
        });

        ref_select_oral_practice_prev.current?.forEach(x => {
            x.selectedIndex = 0;
        });

        ref_select_oral_understand.current?.forEach(x => {
            x.selectedIndex = 0;
        });

        ref_select_oral_understand_prev.current?.forEach(x => {
            x.selectedIndex = 0;
        });
    }

    function reset_ons() {
        ref_select_ons_practice.current?.forEach(x => {
            x.selectedIndex = 0;
        });

        ref_select_ons_practice_prev.current?.forEach(x => {
            x.selectedIndex = 0;
        });

        ref_select_ons_understand.current?.forEach(x => {
            x.selectedIndex = 0;
        });

        ref_select_ons_understand_prev.current?.forEach(x => {
            x.selectedIndex = 0;
        });
    }

    return (
        <>
            { /* Modal confirm submit */ }
            <div className={'absolute top-0 left-0 w-full h-full z-50 ' + (isShow ? 'flex justify-center items-center' : 'hidden')}>
                <div className='w-11/12 h-full bg-white drop-shadow-md rounded-md overflow-y-auto'>
                    <div className='mx-12 my-12'>
                        <h3 className='notoser-bold text-2xl text-orange-primary mt-16 text-center sm:text-start'>แบบประเมินความรู้ความเข้าใจด้านโภชนาการ</h3>

                        <div className='border border-gray-300 rounded-md mt-8'>
                            <button type='button' id='accordion_nutrition_knowledge_oral' 
                            className={"w-full px-8 py-4 text-left flex justify-between items-center " + (is_oral ? 'rounded-t-md border-b ' : 'rounded-md border-none ') + (active_oral ? 'bg-white' : 'bg-gray-300 border-gray-600 cursor-not-allowed')} 
                            onClick={() => {
                                if(active_oral) {
                                    setIs_oral(!is_oral);
                                    setIs_ons(false);
                                }
                            }}>
                                <h3 className={'notoser-bold text-2xl text-center sm:text-start ' + (active_oral ? 'text-orange-primary' : 'text-gray-400')}>สามารถรับประทานอาหารได้ปกติ</h3>
                            </button>

                            <div ref={ref_oral} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: oral_height }}>
                                <div className='pt-8 pb-16 px-8 bg-white'>
                                    <div className='w-full h-fit flex justify-end flex-row-reverse items-start gap-3 bg-orange-primary/20'>
                                        <div className='w-56 h-fit py-2 rounded-tr-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>ปฏิบัติ</b></p>
                                        </div>

                                        <div className='w-56 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>ความเข้าใจ</b></p>
                                        </div>

                                        <div className='grow h-fit py-2 rounded-tl-md'>
                                            <p className='notoser-reg text-web-black text-center invisible'><b>คำถาม</b></p>
                                        </div>
                                    </div>

                                    <div className='w-full h-fit flex justify-end flex-row-reverse items-start gap-3 bg-orange-primary/20 mb-6'>
                                        <div className='w-28 h-fit py-2 rounded-br-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>หลัง</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>ก่อน</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>หลัง</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>ก่อน</b></p>
                                        </div>

                                        <div className='grow h-fit py-2 rounded-bl-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>คำถาม</b></p>
                                        </div>
                                    </div>

                                    {
                                        items_oral.map((x, i) => {
                                            return (
                                                <div key={i} className='w-full h-fit flex justify-end flex-row-reverse gap-3 items-start'>
                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select
                                                        ref={element => ref_select_oral_practice.current[i] = element}
                                                        id={'modal_select_oral_practice_' + day + '_' + i}
                                                        name={'พฤติกรรมข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_oral'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_oral_practice.current.length; j++) {
                                                                const answer = ref_select_oral_practice.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_oral_practice(score);
                                                            reset_ons();
                                                            setActive_oral(true);
                                                            setActive_ons(false);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select
                                                        ref={element => ref_select_oral_practice_prev.current[i] = element}
                                                        id={'modal_select_oral_practice_' + day + '_' + i}
                                                        name={'พฤติกรรมข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_oral'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_oral_practice_prev.current.length; j++) {
                                                                const answer = ref_select_oral_practice_prev.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_oral_practice_prev(score);
                                                            reset_ons();
                                                            setActive_oral(true);
                                                            setActive_ons(false);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select 
                                                        ref={element => ref_select_oral_understand.current[i] = element}
                                                        id={'modal_select_oral_understand_' + day + '_' + i}
                                                        name={'ความเข้าใจข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_oral'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_oral_understand.current.length; j++) {
                                                                const answer = ref_select_oral_understand.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_oral_understand(score);
                                                            reset_ons();
                                                            setActive_oral(true);
                                                            setActive_ons(false);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select 
                                                        ref={element => ref_select_oral_understand_prev.current[i] = element}
                                                        id={'modal_select_oral_understand_' + day + '_' + i}
                                                        name={'ความเข้าใจข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_oral'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_oral_understand_prev.current.length; j++) {
                                                                const answer = ref_select_oral_understand_prev.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_oral_understand_prev(score);
                                                            reset_ons();
                                                            setActive_oral(true);
                                                            setActive_ons(false);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='grow h-fit'>
                                                        <p className={'notoser-reg text-web-black my-4 ml-2 ' + (x.importance ? 'font-bold' : '')}>{i + 1}. {x.question} {x.importance ? '(สำคัญ)' : ''}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className='w-full h-fit flex justify-end flex-row-reverse gap-3 items-start mb-6 bg-orange-primary/20'>
                                        <div className='w-28 h-fit py-2 rounded-e-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_oral_practice}</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2 rounded-e-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_oral_practice_prev}</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_oral_understand}</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_oral_understand_prev}</b></p>
                                        </div>

                                        <div className='grow h-fit py-2 rounded-s-md'>
                                            <p className='notoser-reg text-web-black ml-2'><b>คะแนนรวม</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='border border-gray-300 rounded-md mt-4'>
                            <button type='button' id='accordion_nutrition_knowledge_oral' 
                            className={"w-full px-8 py-4 text-left flex justify-between items-center " + (is_ons ? 'rounded-t-md border-b ' : 'rounded-md border-none ') + (active_ons ? 'bg-white' : 'bg-gray-300 border-gray-600 cursor-not-allowed')} 
                            onClick={() => {
                                if(active_ons) {
                                    setIs_oral(false);
                                    setIs_ons(!is_ons);
                                }
                            }}>
                                <h3 className={'notoser-bold text-2xl text-center sm:text-start ' + (active_ons ? 'text-orange-primary' : 'text-gray-400')}>ได้รับอาหารทางสาย หรืออาหารฝึกกลืน</h3>
                            </button>

                            <div ref={ref_ons} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: ons_height }}>
                                <div className='pt-8 pb-16 px-8 bg-white'>
                                    <div className='w-full h-fit flex justify-end flex-row-reverse items-start gap-3 bg-orange-primary/20'>
                                        <div className='w-56 h-fit py-2 rounded-tr-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>ปฏิบัติ</b></p>
                                        </div>

                                        <div className='w-56 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>ความเข้าใจ</b></p>
                                        </div>

                                        <div className='grow h-fit py-2 rounded-tl-md'>
                                            <p className='notoser-reg text-web-black text-center invisible'><b>คำถาม</b></p>
                                        </div>
                                    </div>

                                    <div className='w-full h-fit flex justify-end flex-row-reverse items-start gap-3 bg-orange-primary/20 mb-6'>
                                        <div className='w-28 h-fit py-2 rounded-br-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>หลัง</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>ก่อน</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>หลัง</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>ก่อน</b></p>
                                        </div>

                                        <div className='grow h-fit py-2 rounded-bl-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>คำถาม</b></p>
                                        </div>
                                    </div>

                                    {
                                        items_ons.map((x, i) => {
                                            return (
                                                <div key={i} className='w-full h-fit flex justify-end flex-row-reverse gap-3 items-start'>
                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select 
                                                        ref={element => ref_select_ons_practice.current[i] = element}
                                                        id={'modal_select_ons_practice_' + day + '_' + i}
                                                        name={'พฤติกรรมข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_ons'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_ons_practice.current.length; j++) {
                                                                const answer = ref_select_ons_practice.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_ons_practice(score);
                                                            reset_oral();
                                                            setActive_oral(false);
                                                            setActive_ons(true);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select 
                                                        ref={element => ref_select_ons_practice_prev.current[i] = element}
                                                        id={'modal_select_ons_practice_' + day + '_' + i}
                                                        name={'พฤติกรรมข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_ons'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_ons_practice_prev.current.length; j++) {
                                                                const answer = ref_select_ons_practice_prev.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_ons_practice_prev(score);
                                                            reset_oral();
                                                            setActive_oral(false);
                                                            setActive_ons(true);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select 
                                                        ref={element => ref_select_ons_understand.current[i] = element}
                                                        id={'modal_select_ons_understand_' + day + '_' + i}
                                                        name={'ความเข้าใจข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_ons'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_ons_understand.current.length; j++) {
                                                                const answer = ref_select_ons_understand.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_ons_understand(score);
                                                            reset_oral();
                                                            setActive_oral(false);
                                                            setActive_ons(true);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='w-fit min-w-28 h-10 my-4'>
                                                        <select 
                                                        ref={element => ref_select_ons_understand_prev.current[i] = element}
                                                        id={'modal_select_ons_understand_' + day + '_' + i}
                                                        name={'ความเข้าใจข้อ' + (i + 1) + 'วันที่' + (day + 1) + '_ons'}
                                                        className={'notoser-reg border focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 pt-2 bg-white border-gray-200'}
                                                        onChange={() => {
                                                            let score = 0;

                                                            for(let j = 0; j < ref_select_ons_understand_prev.current.length; j++) {
                                                                const answer = ref_select_ons_understand_prev.current[j].value;
                                                                score += (answer === '1') ? 1 : 0;
                                                            }

                                                            setScore_ons_understand_prev(score);
                                                            reset_oral();
                                                            setActive_oral(false);
                                                            setActive_ons(true);
                                                        }}>
                                                            <option value='default'></option>
                                                            <option value='N/A'>N/A</option>
                                                            <option value='1'>1 (ใช่)</option>
                                                            <option value='0'>0 (ไม่ใช่)</option>
                                                        </select>
                                                    </div>

                                                    <div className='grow h-fit'>
                                                        <p className='notoser-reg text-web-black my-4 ml-2'>{i + 1}. {x.question}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className='w-full h-fit flex justify-end flex-row-reverse gap-3 items-start mb-6 bg-orange-primary/20'>
                                        <div className='w-28 h-fit py-2 rounded-e-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_ons_practice}</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2 rounded-e-md'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_ons_practice_prev}</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_ons_understand}</b></p>
                                        </div>

                                        <div className='w-28 h-fit py-2'>
                                            <p className='notoser-reg text-web-black text-center'><b>{score_ons_understand_prev}</b></p>
                                        </div>

                                        <div className='grow h-fit py-2 rounded-s-md'>
                                            <p className='notoser-reg text-web-black ml-2'><b>คะแนนรวม</b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <p className='notoser-reg text-web-black mt-8'><u><b>เป้าหมาย</b></u></p>
                        <p className='notoser-reg text-web-black my-4 hidden'>กรณี IPD ผ่านเกณฑ์เมื่อคะแนนรวมของความเข้าใจหลังได้รับคำแนะนำ ≥ 80% (≥ 9 คะแนน) ในผู้ที่รับประทานอาหารได้ปกติ หรือ ≥ 2 คะแนน ในผู้ที่รับอาหารทางสายยางหรืออาหารฝึกกลืน, OPD ผ่านเกณฑ์เมื่อคะแนนการปรับเปลี่ยนพฤติกรรมเพิ่มขึ้นอย่างน้อย 1 คะแนน และควบคุมอาหารกลุ่มไขมันและโซเดียมได้อย่างเหมาะสม (หากเข้าใจหรือปฏิบัติได้ให้ 1 คะแนน หากไม่ไช่ให้ 0 คะแนน ในช่องของแต่ละหัวข้อ)</p>
                        <p className='notoser-reg text-web-black mb-4'>ประเมินผู้ป่วยที่ OPD ว่าผ่านเกณฑ์การปรับพฤติกรรมหรือไม่ โดยพิจารณา ดังนี้</p>
                        <p className='notoser-reg text-web-black mt-4'><u><b>กรณีที่ 1 รับประทานอาหารได้ปกติ</b></u></p>
                        <p className='notoser-reg text-web-black mb-4'>ผ่านเกณฑ์ เมื่อคะแนนการปรับพฤติกรรมหลัง consult เพิ่มขึ้นจากก่อน consult อย่างน้อย 1 คะแนน และได้ 1 คะแนนในข้อ 5, 6, 8</p>
                        <p className='notoser-reg text-web-black mt-4'><u><b>กรณีที่ 2 รับประทานอาหารทางสาย หรืออาหารฝึกกลืน</b></u></p>
                        <p className='notoser-reg text-web-black'>ผ่านเกณฑ์ เมื่อคะแนนการปรับพฤติกรรมหลัง consult เพิ่มขึ้นจากก่อน consult อย่างน้อย 1 คะแนน</p>
                        <p className='notoser-reg text-web-black mb-4'>ผ่านเกณฑ์ = 1 คะแนน<br/>ไม่ผ่านเกณฑ์ = 0 คะแนน</p>
                        <p className='notoser-reg text-web-black mt-4'><u><b>หมายเหตุ</b></u></p>
                        <p className='notoser-reg text-web-black mb-4'>หากผู้ป่วยไม่ได้รับ consult ที่ IPD จะเปรียบเทียบคะแนนการปรับพฤติกรรมหลัง consult ครั้งที่ 2 ว่าเพิ่มขึ้นจากครั้งที่ 1 อย่างน้อย 1 คะแนนหรือไม่</p>

                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-3 w-full h-auto min-h-12 mt-8'}>
                            <button type='button' className='w-full h-full bg-gray-300 hover:bg-gray-500 pt-4 pb-2 rounded-md'
                            onClick={() => {
                                let score_backup_oral_understand = 0;
                                backup_oral_understand.forEach((x, i) => {
                                    if(x === 'default') {
                                        ref_select_oral_understand.current[i].selectedIndex = 0;
                                    }
                                    else if(x === 'N/A') {
                                        ref_select_oral_understand.current[i].selectedIndex = 1;
                                    }
                                    else if(x === '1') {
                                        ref_select_oral_understand.current[i].selectedIndex = 2;
                                        score_backup_oral_understand++;
                                    }
                                    else if(x === '0') {
                                        ref_select_oral_understand.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_oral_understand(score_backup_oral_understand);

                                // Restore oral understand prev and calculate score
                                let score_backup_oral_understand_prev = 0;
                                backup_oral_understand_prev.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_oral_understand_prev.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_oral_understand_prev.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_oral_understand_prev.current[i].selectedIndex = 2;
                                        score_backup_oral_understand_prev++;
                                    } else if (x === '0') {
                                        ref_select_oral_understand_prev.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_oral_understand_prev(score_backup_oral_understand_prev);

                                // Restore oral practice and calculate score
                                let score_backup_oral_practice = 0;
                                backup_oral_practice.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_oral_practice.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_oral_practice.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_oral_practice.current[i].selectedIndex = 2;
                                        score_backup_oral_practice++;
                                    } else if (x === '0') {
                                        ref_select_oral_practice.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_oral_practice(score_backup_oral_practice);

                                // Restore oral practice prev and calculate score
                                let score_backup_oral_practice_prev = 0;
                                backup_oral_practice_prev.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_oral_practice_prev.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_oral_practice_prev.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_oral_practice_prev.current[i].selectedIndex = 2;
                                        score_backup_oral_practice_prev++;
                                    } else if (x === '0') {
                                        ref_select_oral_practice_prev.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_oral_practice_prev(score_backup_oral_practice_prev);

                                // Restore ons understand and calculate score
                                let score_backup_ons_understand = 0;
                                backup_ons_understand.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_ons_understand.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_ons_understand.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_ons_understand.current[i].selectedIndex = 2;
                                        score_backup_ons_understand++;
                                    } else if (x === '0') {
                                        ref_select_ons_understand.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_ons_understand(score_backup_ons_understand);

                                // Restore ons understand prev and calculate score
                                let score_backup_ons_understand_prev = 0;
                                backup_ons_understand_prev.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_ons_understand_prev.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_ons_understand_prev.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_ons_understand_prev.current[i].selectedIndex = 2;
                                        score_backup_ons_understand_prev++;
                                    } else if (x === '0') {
                                        ref_select_ons_understand_prev.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_ons_understand_prev(score_backup_ons_understand_prev);

                                // Restore ons practice and calculate score
                                let score_backup_ons_practice = 0;
                                backup_ons_practice.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_ons_practice.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_ons_practice.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_ons_practice.current[i].selectedIndex = 2;
                                        score_backup_ons_practice++;
                                    } else if (x === '0') {
                                        ref_select_ons_practice.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_ons_practice(score_backup_ons_practice);

                                // Restore ons practice prev and calculate score
                                let score_backup_ons_practice_prev = 0;
                                backup_ons_practice_prev.forEach((x, i) => {
                                    if (x === 'default') {
                                        ref_select_ons_practice_prev.current[i].selectedIndex = 0;
                                    } else if (x === 'N/A') {
                                        ref_select_ons_practice_prev.current[i].selectedIndex = 1;
                                    } else if (x === '1') {
                                        ref_select_ons_practice_prev.current[i].selectedIndex = 2;
                                        score_backup_ons_practice_prev++;
                                    } else if (x === '0') {
                                        ref_select_ons_practice_prev.current[i].selectedIndex = 3;
                                    }
                                });
                                setScore_ons_practice_prev(score_backup_ons_practice_prev);
                                
                                onCancel();
                            }}>ยกเลิก</button>
                            
                            <button type='button' className='w-full h-full bg-orange-primary hover:bg-orange-bold pt-4 pb-2 text-white rounded-md' 
                            onClick={() => {
                                let detail_oral_understand = [];
                                let detail_oral_understand_prev = [];
                                let detail_oral_practice = [];
                                let detail_oral_practice_prev = [];
                                let detail_ons_understand = [];
                                let detail_ons_understand_prev = [];
                                let detail_ons_practice = [];
                                let detail_ons_practice_prev = [];

                                ref_select_oral_understand.current.forEach(x => {
                                    detail_oral_understand.push(x.value);
                                });

                                ref_select_oral_understand_prev.current.forEach(x => {
                                    detail_oral_understand_prev.push(x.value);
                                });

                                ref_select_oral_practice.current.forEach(x => {
                                    detail_oral_practice.push(x.value);
                                });

                                ref_select_oral_practice_prev.current.forEach(x => {
                                    detail_oral_practice_prev.push(x.value);
                                });

                                ref_select_ons_understand.current.forEach(x => {
                                    detail_ons_understand.push(x.value);
                                });

                                ref_select_ons_understand_prev.current.forEach(x => {
                                    detail_ons_understand_prev.push(x.value);
                                });

                                ref_select_ons_practice.current.forEach(x => {
                                    detail_ons_practice.push(x.value);
                                });

                                ref_select_ons_practice_prev.current.forEach(x => {
                                    detail_ons_practice_prev.push(x.value);
                                });

                                const score_understand = (active_oral) ? score_oral_understand : score_ons_understand;
                                const score_understand_prev = (active_oral) ? score_oral_understand_prev : score_ons_understand_prev;
                                const score_practice = (active_oral) ? score_oral_practice : score_ons_practice;
                                const score_practice_prev = (active_oral) ? score_oral_practice_prev : score_ons_practice_prev;
                                
                                onSubmit(
                                    active_oral, 
                                    active_ons, 
                                    score_understand, 
                                    score_understand_prev, 
                                    score_practice, 
                                    score_practice_prev,
                                    detail_oral_understand,
                                    detail_oral_understand_prev,
                                    detail_oral_practice,
                                    detail_oral_practice_prev,
                                    detail_ons_understand,
                                    detail_ons_understand_prev,
                                    detail_ons_practice,
                                    detail_ons_practice_prev
                                );
                            }}>ยืนยัน</button>
                        </div>
                    </div>
                </div>
            </div>

            { /* Modal backdrop */ }
            <div className={'absolute top-0 left-0 w-screen h-screen bg-black opacity-50 z-40 ' + (isShow ? 'block' : 'hidden')}></div>
        </>
    )
}

const CCPC_Medical_conditions = ({handle_onchange}) => {
    const [ medical_condition_is_other, setMedical_condition_is_other ] = useState(false);

    return (
        <>
            <p className='notoser-reg text-web-black my-4'>ภาวะทางการแพทย์</p>

            <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 my-4'>
                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_ACS' name='check_ACS' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Acute Coronary Syndrome (ACS)</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_HF' name='check_HF' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Heart Failure (HF)</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_Stroke' name='check_Stroke' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Acute Ischemic Stroke/ Hemorrhage/ TIA (Stroke)</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_TKA' name='check_TKA' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Total Knee Arthroplasty (TKA)</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_TBI' name='check_TBI' onChange={handle_onchange}/>
                    <p className='notoser-reg text-web-black'>Traumatic Brain Injury (TBI)</p>
                </div>

                <div className='w-full h-fit flex justify-start gap-10'>
                    <input type='checkbox' id='check_CCPC_other' name='check_CCPC_other' onChange={(e) => {
                        document.getElementById('CCPC_other_specify').value = '';
                        setMedical_condition_is_other(e.target.checked);
                        handle_onchange();
                    }}/>
                    <p className='notoser-reg text-web-black'>อื่นๆ</p>
                </div>
            </div>

            <p className='notoser-reg text-web-black mt-8 mb-4'>ภาวะทางการแพทย์อื่นๆ (ถ้ามี)</p>
            <input type='text' id='CCPC_other_specify' name='CCPC_other_specify' placeholder='ภาวะทางการแพทย์อื่นๆ (ถ้ามี)' disabled={!medical_condition_is_other} onChange={handle_onchange}
            className={'border border-gray-200 focus:border-orange-primary focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (medical_condition_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
        </>
    )
}

const CCPC_Nutrition_care_process = ({handle_onchange}) => {
    return (
        <>
            <h3 className='notoser-bold text-2xl text-orange-primary mt-16 text-center sm:text-start'>วันที่เข้ารับและสิ้นสุดการรักษา</h3>
            <p className='text-web-black mt-4'>รายละเอียดการให้กระบวนการโภชนบำบัด</p>
            <Swiper
            className='w-full h-fit mt-4 mb-10'
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                },
                1050: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                680: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            }}
            modules={[Pagination]}
            >
                <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                    <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่เข้ารับการรักษา (Admit)</p>
                    <input type='date' id='admit' name='admit' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full min-w-full h-10 px-4'></input>
                </SwiperSlide>

                <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                    <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วัน Follow up (IPD)</p>
                    <input type='date' id='dayDC' name='addayDCmit' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full min-w-full h-10 px-4'></input>
                </SwiperSlide>

                <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                    <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่เข้าเยี่ยม วันที่ 2 (day2)</p>
                    <input type='date' id='visit_day2' name='visit_day2' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full min-w-full h-10 px-4'></input>
                </SwiperSlide>

                <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                    <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่เข้าเยี่ยม วันที่ 5 (day5)</p>
                    <input type='date' id='visit_day5' name='visit_day5' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full min-w-full h-10 px-4'></input>
                </SwiperSlide>

                <SwiperSlide className='w-full h-full border border-gray-300 rounded-md px-6 py-10 cursor-grab'>
                    <p className='notoser-reg text-web-black line-clamp-1 mb-4'>วันที่สิ้นสุดการรักษา (Discharge)</p>
                    <input type='date' id='discharge' name='discharge' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full min-w-full h-10 px-4'></input>
                </SwiperSlide>
            </Swiper>
        </>
    )
}

const CCPC_Behavior_score = ({handle_onchange}) => {
    const [ is_BehaveScore_show0, setIsBehaveScore_show0 ] = useState(false);
    const [ is_BehaveScore_show1, setIsBehaveScore_show1 ] = useState(false);
    const [ is_BehaveScore_show2, setIsBehaveScore_show2 ] = useState(false);

    const ref_foodType0 = useRef(null);
    const ref_foodType1 = useRef(null);
    const ref_foodType2 = useRef(null);
    const ref_scoreUnderstand0 = useRef(null);
    const ref_scoreUnderstand1 = useRef(null);
    const ref_scoreUnderstand2 = useRef(null);
    const ref_scorePractice0 = useRef(null);
    const ref_scorePractice1 = useRef(null);
    const ref_scorePractice2 = useRef(null);
    const ref_auto_understand = useRef(null);
    const ref_auto_practice = useRef(null);
    const ref_prev_scoreUnderstand0 = useRef(null);
    const ref_prev_scorePractice0 = useRef(null);
    const ref_prev_scoreUnderstand1 = useRef(null);
    const ref_prev_scorePractice1 = useRef(null);
    const ref_prev_scoreUnderstand2 = useRef(null);
    const ref_prev_scorePractice2 = useRef(null);

    const [ detail_oral_scoreUnderstand0, setDetail_oral_scoreUnderstand0 ] = useState([]);
    const [ detail_oral_scorePractice0, setDetail_oral_scorePractice0 ] = useState([]);
    const [ detail_prev_oral_scoreUnderstand0, setDetail_prev_oral_scoreUnderstand0 ] = useState([]);
    const [ detail_prev_oral_scorePractice0, setDetail_prev_oral_scorePractice0 ] = useState([]);
    const [ detail_ons_scoreUnderstand0, setDetail_ons_scoreUnderstand0 ] = useState([]);
    const [ detail_ons_scorePractice0, setDetail_ons_scorePractice0 ] = useState([]);
    const [ detail_prev_ons_scoreUnderstand0, setDetail_prev_ons_scoreUnderstand0 ] = useState([]);
    const [ detail_prev_ons_scorePractice0, setDetail_prev_ons_scorePractice0 ] = useState([]);

    const [ detail_oral_scoreUnderstand1, setDetail_oral_scoreUnderstand1 ] = useState([]);
    const [ detail_oral_scorePractice1, setDetail_oral_scorePractice1 ] = useState([]);
    const [ detail_prev_oral_scoreUnderstand1, setDetail_prev_oral_scoreUnderstand1 ] = useState([]);
    const [ detail_prev_oral_scorePractice1, setDetail_prev_oral_scorePractice1 ] = useState([]);
    const [ detail_ons_scoreUnderstand1, setDetail_ons_scoreUnderstand1 ] = useState([]);
    const [ detail_ons_scorePractice1, setDetail_ons_scorePractice1 ] = useState([]);
    const [ detail_prev_ons_scoreUnderstand1, setDetail_prev_ons_scoreUnderstand1 ] = useState([]);
    const [ detail_prev_ons_scorePractice1, setDetail_prev_ons_scorePractice1 ] = useState([]);

    const [ detail_oral_scoreUnderstand2, setDetail_oral_scoreUnderstand2 ] = useState([]);
    const [ detail_oral_scorePractice2, setDetail_oral_scorePractice2 ] = useState([]);
    const [ detail_prev_oral_scoreUnderstand2, setDetail_prev_oral_scoreUnderstand2 ] = useState([]);
    const [ detail_prev_oral_scorePractice2, setDetail_prev_oral_scorePractice2 ] = useState([]);
    const [ detail_ons_scoreUnderstand2, setDetail_ons_scoreUnderstand2 ] = useState([]);
    const [ detail_ons_scorePractice2, setDetail_ons_scorePractice2 ] = useState([]);
    const [ detail_prev_ons_scoreUnderstand2, setDetail_prev_ons_scoreUnderstand2 ] = useState([]);
    const [ detail_prev_ons_scorePractice2, setDetail_prev_ons_scorePractice2 ] = useState([]);

    const [ auto_translate_understand_label, setAuto_translate_understand_label ] = useState('');
    const [ auto_translate_practice_label, setAuto_translate_practice_label ] = useState('');

    const [ auto_behav_understand, setAuto_behav_understand ] = useState(false);
    const [ auto_behav_behav, setAuto_behav_practice ] = useState(false);

    const [ default_behave_items_oral, setDefault_behave_items_oral ] = useState([
        {
            question: 'เลือกรับประทานอาหารประเภทข้าวแป้ง ในสัดส่วนที่เหมาะสม',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'เลือกรับประทานอาหารประเภทโปรตีน ในสัดส่วนที่เหมาะสม',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'เลือกรับประทานอาหารประเภทผลไม้ น้ำผลไม้ ในสัดส่วนที่เหมาะสม',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'หลีกเลี่ยงขนมหวาน ของหวาน และเบเกอรี่ต่างๆ',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'หลีกเลี่ยงอาหารประเภทไขมันอิ่มตัวสูง อาหารทอด อาหารที่ปรุงจากกะทิ และอาหารที่ปรุงน้ำมันมาก',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: true,
        },
        {
            question: 'หลีกเลี่ยงอาหารที่มีไขมันทรานส์สูง',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: true,
        },
        {
            question: 'สามารถเลือกใช้น้ำมันชนิดต่างๆ ในการปรุงอาหารได้ถูกต้อง',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'หลีกเลี่ยงอาหารที่มีโซเดียมสูง',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: true,
        },
        {
            question: 'อ่านฉลากโภชนาการ ก่อนเลือกซื้อผลิตภัณฑ์',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'เลือกรับประทานอาหารที่มีกากใยอาหารสูง',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'ไม่ดื่มแอลกอฮอล์ หรือมีการลดสัดส่วนให้เหมาะสม',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
    ]);

    const [ default_behave_items_ons, setDefault_behave_items_ons ] = useState([
        {
            question: 'ได้รับพลังงานเพียงพอกับความต้องการของร่างกาย',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'ได้รับโปรตีนเพียงพอกับความต้องการของร่างกาย',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
        {
            question: 'เตรียมอาหารได้อย่างเหมาะสม',
            answer_understand: "N/A",
            answer_practice: "N/A",
            importance: false,
        },
    ]);

    const [ form_behav_items_oral, setForm_behave_items_oral ] = useState([
        {
            followup: 'ก่อน consult',
            score_understand: 0,
            score_practice: 0,
            active: true,
            detail: [...default_behave_items_oral],
            prev_detail: [...default_behave_items_oral],
        },
        {
            followup: 'หลัง consult1',
            score_understand: 0,
            score_practice: 0,
            active: true,
            detail: [...default_behave_items_oral],
            prev_detail: [...default_behave_items_oral],
        },
        {
            followup: 'หลัง consult2',
            score_understand: 0,
            score_practice: 0,
            active: true,
            detail: [...default_behave_items_oral],
            prev_detail: [...default_behave_items_oral],
        },
    ]);

    const [ form_behav_items_ons, setForm_behave_items_ons ] = useState([
        {
            followup: 'ก่อน consult',
            score_understand: 0,
            score_practice: 0,
            active: true,
            detail: [...default_behave_items_ons],
            prev_detail: [...default_behave_items_ons],
        },
        {
            followup: 'หลัง consult1',
            score_understand: 0,
            score_practice: 0,
            active: true,
            detail: [...default_behave_items_ons],
            prev_detail: [...default_behave_items_ons],
        },
        {
            followup: 'หลัง consult2',
            score_understand: 0,
            score_practice: 0,
            active: true,
            detail: [...default_behave_items_ons],
            prev_detail: [...default_behave_items_ons],
        },
    ]);

    useEffect(() => {
        if(!is_BehaveScore_show0) {
            auto_translate_behav_score();
        }
    }, [is_BehaveScore_show0]);

    useEffect(() => {
        if(!is_BehaveScore_show1) {
            auto_translate_behav_score();
        }
    }, [is_BehaveScore_show1]);

    useEffect(() => {
        if(!is_BehaveScore_show2) {
            auto_translate_behav_score();
        }
    }, [is_BehaveScore_show2]);

    function auto_translate_behav_score() {
        setAuto_translate_understand_label('');
        setAuto_translate_practice_label('');

        //At this state, components of foodtype, score_understand and score_practice will already be filled if not null
        //if IPD null, end function and ignore the rest
        if(ref_foodType0.current.selectedIndex === 0 && ref_scoreUnderstand0.current.value === '' && ref_scorePractice0.current.value === '') {
            ref_auto_understand.current.selectedIndex = 0;
            setAuto_behav_understand(false);
            return;
        }

        //if components of IPD at least one not empty, set auto_understand to 'N/A' first
        //if information of IPD do not support translation, the result of 'N/A' will not be changed
        if(ref_foodType0.current.selectedIndex !== 0 || ref_scoreUnderstand0.current.value !== '' || ref_scorePractice0.current.value !== '') {
            ref_auto_understand.current.value = 'N/A';
            setAuto_translate_understand_label('IPD');
            setAuto_behav_understand(true);
        }

        //auto translate ref_scoreUnderstand of IPD first
        if(ref_foodType0.current.selectedIndex !== 0 && ref_scoreUnderstand0.current.value !== '') {
            ref_auto_understand.current.value = determine_pass_understand(ref_foodType0.current.value, ref_scoreUnderstand0.current.value);
            setAuto_translate_understand_label('IPD');
            setAuto_behav_understand(true);
        }

        //auto translate ref_scorePractice of IPD
        //if there is score, detail cannot be empty, so do not check condition for detail (to use in determine_pass_practice())
        if(ref_foodType0.current.selectedIndex !== 0 && ref_scorePractice0.current.value !== '' && ref_prev_scorePractice0.current.value !== '') {
            ref_auto_practice.current.value = determine_pass_practice(ref_foodType0.current.value, ref_scorePractice0.current.value, ref_prev_scorePractice0.current.value, detail_oral_scorePractice0);
            setAuto_translate_practice_label('IPD');
            setAuto_behav_practice(true);
        }

        //if OPD1 null, end function and ignore the rest
        if(ref_foodType1.current.selectedIndex === 0 && ref_scoreUnderstand1.current.value === '' && ref_scorePractice1.current.value === '') {
            ref_auto_practice.current.selectedIndex = 0;
            setAuto_behav_practice(false);
            return;
        }

        //if components of OPD1 at least one not empty, set auto_practice to 'N/A' first
        //if information of OPD1 do not support translation, the result of 'N/A' will not be changed
        if(ref_foodType1.current.selectedIndex !== 0 || ref_scoreUnderstand1.current.value !== '' || ref_scorePractice1.current.value !== '') {
            ref_auto_practice.current.value = 'N/A';
            setAuto_translate_practice_label('OPD1');
            setAuto_behav_practice(true);
        }

        //auto translate understand of OPD1 first
        if(ref_foodType1.current.selectedIndex !== 0 && ref_scoreUnderstand1.current.value !== '') {
            ref_auto_understand.current.value = determine_pass_understand(ref_foodType1.current.value, ref_scoreUnderstand1.current.value);
            setAuto_translate_understand_label('OPD1');
            setAuto_behav_understand(true);
        }
        
        //auto translate practice of OPD1 next
        if(ref_foodType1.current.selectedIndex !== 0 && ref_scorePractice1.current.value !== '') {
            //if foodType of IPD and OPD1 are not same, 
            //donot ignore the rest to allow OPD2 to be checked
            if(ref_foodType0.current.value !== ref_foodType1.current.value) {
                ref_auto_practice.current.value = 'N/A';
                setAuto_translate_practice_label('OPD1');
                setAuto_behav_practice(true);
            }
            else if(ref_foodType0.current.value === ref_foodType1.current.value) {
                ref_auto_practice.current.value = determine_pass_practice(ref_foodType1.current.value, ref_scorePractice1.current.value, ref_scorePractice0.current.value, detail_oral_scorePractice1);
                setAuto_translate_practice_label('OPD1');
                setAuto_behav_practice(true);
            }

            const OPD_prev_empty = determine_if_detail_empty((ref_foodType1.current.value !== 'ไม่ระบุ' && ref_foodType1.current.value === 'อาหารปกติ') ? detail_prev_oral_scorePractice1 : detail_prev_ons_scorePractice1);

            //if new prev is added, translate again
            if(!OPD_prev_empty) {
                ref_auto_practice.current.value = determine_pass_practice(ref_foodType1.current.value, ref_scorePractice1.current.value, ref_prev_scorePractice1.current.value, detail_oral_scorePractice1);
                setAuto_translate_practice_label('OPD1');
                setAuto_behav_practice(true);
            }
        }

        //if OPD2 null, do nothing, refers result of OPD1 and end function
        if(ref_foodType2.current.selectedIndex === 0 && ref_scoreUnderstand2.current.value === '' && ref_scorePractice2.current.value === '') {
            return;
        }

        //if components of OPD2 at least one not empty, set auto_practice to 'N/A' first
        //if information of OPD2 do not support translation, the result of 'N/A' will not be changed
        if(ref_foodType2.current.selectedIndex !== 0 || ref_scoreUnderstand2.current.value !== '' || ref_scorePractice2.current.value !== '') {
            ref_auto_practice.current.value = 'N/A';
            setAuto_translate_practice_label('OPD2');
            setAuto_behav_practice(true);
        }

        //auto translate understand of OPD2 first
        if(ref_foodType2.current.selectedIndex !== 0 && ref_scoreUnderstand2.current.value !== '') {
            ref_auto_understand.current.value = determine_pass_understand(ref_foodType2.current.value, ref_scoreUnderstand2.current.value);
            setAuto_translate_understand_label('OPD2');
            setAuto_behav_understand(true);
        }

        //auto translate practice of OPD2 next
        if(ref_foodType2.current.selectedIndex !== 0 && ref_scorePractice2.current.value !== '') {
            //if foodType of OPD1 and OPD2 are not same, 
            //donot ignore the rest to allow OPD2 to be checked
            if(ref_foodType1.current.value !== ref_foodType2.current.value) {
                ref_auto_practice.current.value = 'N/A';
                setAuto_translate_practice_label('OPD2');
                setAuto_behav_practice(true);
            }
            else if(ref_foodType1.current.value === ref_foodType2.current.value) {
                ref_auto_practice.current.value = determine_pass_practice(ref_foodType2.current.value, ref_scorePractice2.current.value, ref_scorePractice1.current.value, detail_oral_scorePractice2);
                setAuto_translate_practice_label('OPD2');
                setAuto_behav_practice(true);
            }

            const OPD_prev_empty = determine_if_detail_empty((ref_foodType2.current.value !== 'ไม่ระบุ' && ref_foodType2.current.value === 'อาหารปกติ') ? detail_prev_oral_scorePractice2 : detail_prev_ons_scorePractice2);
            
            //if new prev is added, translate again
            if(!OPD_prev_empty) {
                ref_auto_practice.current.value = determine_pass_practice(ref_foodType2.current.value, ref_scorePractice2.current.value, ref_prev_scorePractice2.current.value, detail_oral_scorePractice2);
                setAuto_translate_practice_label('OPD2');
                setAuto_behav_practice(true);
            }
        }
    }

    function determine_if_detail_empty(detail) {
        let empty = true;

        for(let i = 0; i < detail.length; i++) {
            if(detail[i] !== 'default') {
                empty = false;
                break;
            }
        }

        return detail.length === 0 || empty;
    }

    function determine_pass_practice(foodType, score, prev_score, oral_detail_practice) {
        const index_sat_fat = default_behave_items_oral.findIndex(x => x.question === 'หลีกเลี่ยงอาหารประเภทไขมันอิ่มตัวสูง อาหารทอด อาหารที่ปรุงจากกะทิ และอาหารที่ปรุงน้ำมันมาก');
        const index_trans_fat = default_behave_items_oral.findIndex(x => x.question === 'หลีกเลี่ยงอาหารที่มีไขมันทรานส์สูง');
        const index_sodium = default_behave_items_oral.findIndex(x => x.question === 'หลีกเลี่ยงอาหารที่มีโซเดียมสูง');

        let result = 'N/A';

        if(foodType === 'อาหารปกติ') {
            const answer5 = oral_detail_practice[index_sat_fat];
            const answer6 = oral_detail_practice[index_trans_fat];
            const answer8 = oral_detail_practice[index_sodium];

            console.log(oral_detail_practice);

            //if manual inserting score, answer5, answer6 and answer8 should be N/A
            //then, set auto translation to N/A because these answers is must
            if((answer5 === '0' || answer5 === '1') && (answer6 === '0' || answer6 === '1') && (answer8 === '0' || answer8 === '1')) {
                //console.log(score + " / " + prev_score + " / " + answer5 + " / " + answer6 + " / " + answer8);
                result = ((parseInt(score) > parseInt(prev_score)) && (answer5 === '1' && answer6 === '1' && answer8 === '1')) ? '1' : '0';
            }
        }
        else if(foodType === 'อาหารทางสายหรือฝึกกลืน') {
            result = (score > prev_score) ? '1' : '0';
        }

        return result;
    }

    function determine_pass_understand(foodType, score_understand) {
        const pass_oral = (foodType === 'อาหารปกติ') && (parseInt(score_understand) >= 9);
        const pass_ons = (foodType === 'อาหารทางสายหรือฝึกกลืน') && (parseInt(score_understand) >= 2);

        return (pass_oral || pass_ons) ? '1' : '0';
    }

    return (
        <>
            <h3 className='notoser-bold text-2xl text-orange-primary mt-16 mb-10 text-center sm:text-start'>คะแนนการปรับพฤติกรรม (เฉพาะ ACS, Stroke)</h3>
            <Swiper
            className='w-full h-fit mb-10'
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                1050: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                680: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            }}
            modules={[Pagination]}
            >
                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินก่อน consult (IPD)</p>
                    <input type='date' id='Date_AS0' name='Date_AS0' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>

                    <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร</p>
                    <select id='ลักษณะอาหาร_AS0' name='ลักษณะอาหาร_AS0' ref={ref_foodType0} onChange={(e) => {
                        const element_score_understand = document.getElementById('คะแนนความเข้าใจ0');
                        const element_score_practice = document.getElementById('คะแนนพฤติกรรม0');

                        if(e.target.value === 'อาหารปกติ') {
                            let new_form = [...form_behav_items_oral];
                            new_form[0].score_understand = (element_score_understand.value === '') ? 0 : element_score_understand.value;
                            new_form[0].score_practice = (element_score_practice.value === '') ? 0 : element_score_practice.value;
                            setForm_behave_items_oral(new_form);
                        }
                        else if(e.target.value === 'อาหารทางสายหรือฝึกกลืน') {
                            let new_form = [...form_behav_items_ons];
                            new_form[0].score_understand = (element_score_understand.value === '') ? 0 : element_score_understand.value;
                            new_form[0].score_practice = (element_score_practice.value === '') ? 0 : element_score_practice.value;
                            setForm_behave_items_ons(new_form);
                        }

                        auto_translate_behav_score();
                        handle_onchange();
                    }}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-2'>
                        <option value="ไม่ระบุ">โปรดระบุลักษณะอาหาร</option>
                        <option value="อาหารปกติ">อาหารปกติ</option>
                        <option value="อาหารทางสายหรือฝึกกลืน">อาหารทางสายหรือฝึกกลืน</option>
                    </select>

                    <p className='notoser-reg text-orange-primary mt-0 lg:mt-8 mb-2'><b>คะแนนพฤติกรรม (ก่อน)</b></p>
                    
                    <div className='grid grid-cols-2 justify-start gap-5'>
                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                            <input type='text' ref={ref_prev_scoreUnderstand0} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนความเข้าใจ'></input>
                        </div>

                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                            <input type='text' ref={ref_prev_scorePractice0} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนพฤติกรรม'></input>
                        </div>
                    </div>

                    <p className='notoser-reg text-orange-primary mt-0 lg:mt-8 mb-2'><b>คะแนนพฤติกรรม (หลัง)</b></p>
                    
                    <div className='grid grid-cols-2 justify-start gap-5'>
                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                            <input type='text' id='คะแนนความเข้าใจ0' name='คะแนนความเข้าใจ0' ref={ref_scoreUnderstand0} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนความเข้าใจ'></input>
                        </div>

                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                            <input type='text' id='คะแนนพฤติกรรม0' name='คะแนนพฤติกรรม0' ref={ref_scorePractice0} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนพฤติกรรม'></input>
                        </div>
                    </div>

                    <button type='button' className='notoser-semi w-full h-fit bg-orange-primary text-white px-10 py-3 mt-6 rounded-md hover:bg-orange-bold' 
                    onClick={() => setIsBehaveScore_show0(true)}>เริ่มประเมิน</button>
                </SwiperSlide>

                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>หลัง consult ครั้งที่ 1</p>
                    <input type='date' id='Date_AS1' name='Date_AS1' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>

                    <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร</p>
                    <select id='ลักษณะอาหาร_AS1' name='ลักษณะอาหาร_AS1' ref={ref_foodType1} onChange={(e) => {
                        const element_score_understand = document.getElementById('คะแนนความเข้าใจ1');
                        const element_score_practice = document.getElementById('คะแนนพฤติกรรม1');

                        if(e.target.value === 'อาหารปกติ') {
                            let new_form = [...form_behav_items_oral];
                            new_form[1].score_understand = (element_score_understand.value === '') ? 0 : element_score_understand.value;
                            new_form[1].score_practice = (element_score_practice.value === '') ? 0 : element_score_practice.value;
                            setForm_behave_items_oral(new_form);
                        }
                        else if(e.target.value === 'อาหารทางสายหรือฝึกกลืน') {
                            let new_form = [...form_behav_items_ons];
                            new_form[1].score_understand = (element_score_understand.value === '') ? 0 : element_score_understand.value;
                            new_form[1].score_practice = (element_score_practice.value === '') ? 0 : element_score_practice.value;
                            setForm_behave_items_ons(new_form);
                        }

                        auto_translate_behav_score();
                        handle_onchange();
                    }}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-2'>
                        <option value="ไม่ระบุ">โปรดระบุลักษณะอาหาร</option>
                        <option value="อาหารปกติ">อาหารปกติ</option>
                        <option value="อาหารทางสายหรือฝึกกลืน">อาหารทางสายหรือฝึกกลืน</option>
                    </select>

                    <p className='notoser-reg text-orange-primary mt-0 lg:mt-8 mb-2'><b>คะแนนพฤติกรรม (ก่อน)</b></p>
                    
                    <div className='grid grid-cols-2 justify-start gap-5'>
                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                            <input type='text' ref={ref_prev_scoreUnderstand1} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนความเข้าใจ'></input>
                        </div>

                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                            <input type='text' ref={ref_prev_scorePractice1} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนพฤติกรรม'></input>
                        </div>
                    </div>

                    <p className='notoser-reg text-orange-primary mt-0 lg:mt-8 mb-2'><b>คะแนนพฤติกรรม (หลัง)</b></p>
                    
                    <div className='grid grid-cols-2 justify-start gap-5'>
                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                            <input type='text' id='คะแนนความเข้าใจ1' name='คะแนนความเข้าใจ1' ref={ref_scoreUnderstand1} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนความเข้าใจ'></input>
                        </div>

                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                            <input type='text' id='คะแนนพฤติกรรม1' name='คะแนนพฤติกรรม1' ref={ref_scorePractice1} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนพฤติกรรม'></input>
                        </div>
                    </div>

                    <button type='button' className='notoser-semi w-full h-fit bg-orange-primary text-white px-10 py-3 mt-6 rounded-md hover:bg-orange-bold' 
                    onClick={() => setIsBehaveScore_show1(true)}>เริ่มประเมิน</button>
                </SwiperSlide>

                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>หลัง consult ครั้งที่ 2</p>
                    <input type='date' id='Date_AS2' name='Date_AS2' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>

                    <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร</p>
                    <select id='ลักษณะอาหาร_AS2' name='ลักษณะอาหาร_AS2' ref={ref_foodType2} onChange={(e) => {
                        const element_score_understand = document.getElementById('คะแนนความเข้าใจ2');
                        const element_score_practice = document.getElementById('คะแนนพฤติกรรม2');

                        if(e.target.value === 'อาหารปกติ') {
                            let new_form = [...form_behav_items_oral];
                            new_form[2].score_understand = (element_score_understand.value === '') ? 0 : element_score_understand.value;
                            new_form[2].score_practice = (element_score_practice.value === '') ? 0 : element_score_practice.value;
                            setForm_behave_items_oral(new_form);
                        }
                        else if(e.target.value === 'อาหารทางสายหรือฝึกกลืน') {
                            let new_form = [...form_behav_items_ons];
                            new_form[2].score_understand = (element_score_understand.value === '') ? 0 : element_score_understand.value;
                            new_form[2].score_practice = (element_score_practice.value === '') ? 0 : element_score_practice.value;
                            setForm_behave_items_ons(new_form);
                        }

                        auto_translate_behav_score();
                        handle_onchange();
                    }}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-2'>
                        <option value="ไม่ระบุ">โปรดระบุลักษณะอาหาร</option>
                        <option value="อาหารปกติ">อาหารปกติ</option>
                        <option value="อาหารทางสายหรือฝึกกลืน">อาหารทางสายหรือฝึกกลืน</option>
                    </select>

                    <p className='notoser-reg text-orange-primary mt-0 lg:mt-8 mb-2'><b>คะแนนพฤติกรรม (ก่อน)</b></p>
                    
                    <div className='grid grid-cols-2 justify-start gap-5'>
                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                            <input type='text' ref={ref_prev_scoreUnderstand2} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนความเข้าใจ'></input>
                        </div>

                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                            <input type='text' ref={ref_prev_scorePractice2} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนพฤติกรรม'></input>
                        </div>
                    </div>

                    <p className='notoser-reg text-orange-primary mt-0 lg:mt-8 mb-2'><b>คะแนนพฤติกรรม (หลัง)</b></p>
                    
                    <div className='grid grid-cols-2 justify-start gap-5'>
                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนความเข้าใจ</p>
                            <input type='text' id='คะแนนความเข้าใจ2' name='คะแนนความเข้าใจ2' ref={ref_scoreUnderstand2} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนความเข้าใจ'></input>
                        </div>

                        <div className='grow h-fit'>
                            <p className='notoser-reg text-web-black mb-2'>คะแนนพฤติกรรม</p>
                            <input type='text' id='คะแนนพฤติกรรม2' name='คะแนนพฤติกรรม2' ref={ref_scorePractice2} onChange={() => {
                                auto_translate_behav_score();
                                handle_onchange();
                            }}
                            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='ระบุคะแนนพฤติกรรม'></input>
                        </div>
                    </div>

                    <button type='button' className='notoser-semi w-full h-fit bg-orange-primary text-white px-10 py-3 mt-6 rounded-md hover:bg-orange-bold' 
                    onClick={() => setIsBehaveScore_show2(true)}>เริ่มประเมิน</button>
                </SwiperSlide>
            </Swiper>

            <p className='notoser-reg text-web-black my-4'>การแปลผลความเข้าใจ (สำหรับ ACS, Stroke)(แปลผลอัตโนมัติ)</p>
            <select id='ผ่านเกณฑ์ความเข้าใจ_AS' name='ผ่านเกณฑ์ความเข้าใจ_AS' ref={ref_auto_understand} disabled={true}
            className={'border focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4 cursor-not-allowed ' + (auto_behav_understand ? 'bg-green-100 border-green-500' : 'bg-white border-gray-200')}>
                <option value="ไม่ระบุ">โปรดระบุการแปรผล</option>
                <option value="N/A">{auto_translate_understand_label + " : "}N/A</option>
                <option value="0">{auto_translate_understand_label + " : "}ไม่ผ่าน (0)</option>
                <option value="1">{auto_translate_understand_label + " : "}ผ่าน (1)</option>
            </select>

            <p className='notoser-reg text-web-black my-4'>การแปลผลพฤติกรรม (สำหรับ ACS, Stroke)(แปลผลอัตโนมัติ)</p>
            <select id='ผ่านเกณฑ์พฤติกรรม_AS' name='ผ่านเกณฑ์พฤติกรรม_AS' ref={ref_auto_practice} disabled={true}
            className={'border focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4 cursor-not-allowed ' + (auto_behav_behav ? 'bg-green-100 border-green-500' : 'bg-white border-gray-200')}>
                <option value="ไม่ระบุ">โปรดระบุการแปรผล</option>
                <option value="N/A">{auto_translate_practice_label + " : "}N/A</option>
                <option value="0">{auto_translate_practice_label + " : "}ไม่ผ่าน (0)</option>
                <option value="1">{auto_translate_practice_label + " : "}ผ่าน (1)</option>
            </select>

            <p className='notoser-reg text-web-black my-4'>การแปลผล (สำหรับ TKA, HF, TBI)</p>
            <select id='ผ่านเกณฑ์_THT' name='ผ่านเกณฑ์_THT' 
            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4'>
                <option value="ไม่ระบุ">โปรดระบุการแปรผล</option>
                <option value="0">ไม่ผ่าน (0)</option>
                <option value="1">ผ่าน (1)</option>
            </select>

            {
                (detail_oral_scoreUnderstand0.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่1_oral_หลัง' name='ตัวเลือกความเข้าใจวันที่1_oral_หลัง' value={detail_oral_scoreUnderstand0.join(",")}></input> : null
            }

            {
                (detail_oral_scorePractice0.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่1_oral_หลัง' name='ตัวเลือกพฤติกรรมวันที่1_oral_หลัง' value={detail_oral_scorePractice0.join(",")}></input> : null
            }

            {
                (detail_prev_oral_scoreUnderstand0.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่1_oral_ก่อน' name='ตัวเลือกความเข้าใจวันที่1_oral_ก่อน' value={detail_prev_oral_scoreUnderstand0.join(",")}></input> : null
            }

            {
                (detail_prev_oral_scorePractice0.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่1_oral_ก่อน' name='ตัวเลือกพฤติกรรมวันที่1_oral_ก่อน' value={detail_prev_oral_scorePractice0.join(",")}></input> : null
            }

            {
                (detail_ons_scoreUnderstand0.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่1_ons_หลัง' name='ตัวเลือกความเข้าใจวันที่1_ons_หลัง' value={detail_ons_scoreUnderstand0.join(",")}></input> : null
            }

            {
                (detail_ons_scorePractice0.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่1_ons_หลัง' name='ตัวเลือกพฤติกรรมวันที่1_ons_หลัง' value={detail_ons_scorePractice0.join(",")}></input> : null
            }

            {
                (detail_prev_ons_scoreUnderstand0.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่1_ons_ก่อน' name='ตัวเลือกความเข้าใจวันที่1_ons_ก่อน' value={detail_prev_ons_scoreUnderstand0.join(",")}></input> : null
            }

            {
                (detail_prev_ons_scorePractice0.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่1_ons_ก่อน' name='ตัวเลือกพฤติกรรมวันที่1_ons_ก่อน' value={detail_prev_ons_scorePractice0.join(",")}></input> : null
            }

            {
                (detail_oral_scoreUnderstand1.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่2_oral_หลัง' name='ตัวเลือกความเข้าใจวันที่2_oral_หลัง' value={detail_oral_scoreUnderstand1.join(",")}></input> : null
            }

            {
                (detail_oral_scorePractice1.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่2_oral_หลัง' name='ตัวเลือกพฤติกรรมวันที่2_oral_หลัง' value={detail_oral_scorePractice1.join(",")}></input> : null
            }

            {
                (detail_prev_oral_scoreUnderstand1.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่2_oral_ก่อน' name='ตัวเลือกความเข้าใจวันที่2_oral_ก่อน' value={detail_prev_oral_scoreUnderstand1.join(",")}></input> : null
            }

            {
                (detail_prev_oral_scorePractice1.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่2_oral_ก่อน' name='ตัวเลือกพฤติกรรมวันที่2_oral_ก่อน' value={detail_prev_oral_scorePractice1.join(",")}></input> : null
            }

            {
                (detail_ons_scoreUnderstand1.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่2_ons_หลัง' name='ตัวเลือกความเข้าใจวันที่2_ons_หลัง' value={detail_ons_scoreUnderstand1.join(",")}></input> : null
            }

            {
                (detail_ons_scorePractice1.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่2_ons_หลัง' name='ตัวเลือกพฤติกรรมวันที่2_ons_หลัง' value={detail_ons_scorePractice1.join(",")}></input> : null
            }

            {
                (detail_prev_ons_scoreUnderstand1.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่2_ons_ก่อน' name='ตัวเลือกความเข้าใจวันที่2_ons_ก่อน' value={detail_prev_ons_scoreUnderstand1.join(",")}></input> : null
            }

            {
                (detail_prev_ons_scorePractice1.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่2_ons_ก่อน' name='ตัวเลือกพฤติกรรมวันที่2_ons_ก่อน' value={detail_prev_ons_scorePractice1.join(",")}></input> : null
            }

            {
                (detail_oral_scoreUnderstand2.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่3_oral_หลัง' name='ตัวเลือกความเข้าใจวันที่3_oral_หลัง' value={detail_oral_scoreUnderstand2.join(",")}></input> : null
            }

            {
                (detail_oral_scorePractice2.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่3_oral_หลัง' name='ตัวเลือกพฤติกรรมวันที่3_oral_หลัง' value={detail_oral_scorePractice2.join(",")}></input> : null
            }

            {
                (detail_prev_oral_scoreUnderstand2.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่3_oral_ก่อน' name='ตัวเลือกความเข้าใจวันที่3_oral_ก่อน' value={detail_prev_oral_scoreUnderstand2.join(",")}></input> : null
            }

            {
                (detail_prev_oral_scorePractice2.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่3_oral_ก่อน' name='ตัวเลือกพฤติกรรมวันที่3_oral_ก่อน' value={detail_prev_oral_scorePractice2.join(",")}></input> : null
            }

            {
                (detail_ons_scoreUnderstand2.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่3_ons_หลัง' name='ตัวเลือกความเข้าใจวันที่3_ons_หลัง' value={detail_ons_scoreUnderstand2.join(",")}></input> : null
            }

            {
                (detail_ons_scorePractice2.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่3_ons_หลัง' name='ตัวเลือกพฤติกรรมวันที่3_ons_หลัง' value={detail_ons_scorePractice2.join(",")}></input> : null
            }

            {
                (detail_prev_ons_scoreUnderstand2.length > 0) ? <input className='hidden' id='ตัวเลือกความเข้าใจวันที่3_ons_ก่อน' name='ตัวเลือกความเข้าใจวันที่3_ons_ก่อน' value={detail_prev_ons_scoreUnderstand2.join(",")}></input> : null
            }

            {
                (detail_prev_ons_scorePractice2.length > 0) ? <input className='hidden' id='ตัวเลือกพฤติกรรมวันที่3_ons_ก่อน' name='ตัวเลือกพฤติกรรมวันที่3_ons_ก่อน' value={detail_prev_ons_scorePractice2.join(",")}></input> : null
            }

            <ModalBehavScore 
            isShow={is_BehaveScore_show0} 
            items_oral={default_behave_items_oral}
            items_ons={default_behave_items_ons}
            day={0}
            onCancel={() => setIsBehaveScore_show0(false)}
            onSubmit={(oral, ons, 
                score_understand, score_understand_prev, score_practice, score_practice_prev, 
                detail_oral_understand, detail_oral_understand_prev, detail_oral_practice, detail_oral_practice_prev,
                detail_ons_understand, detail_ons_understand_prev, detail_ons_practice, detail_ons_practice_prev) => {

                if(oral && ons) {
                    ref_foodType0.current.selectedIndex = 0;
                }
                else {
                    ref_foodType0.current.selectedIndex = (oral) ? 1 : 2;
                }

                ref_prev_scoreUnderstand0.current.value = score_understand_prev;
                ref_prev_scorePractice0.current.value = score_practice_prev;
                ref_scoreUnderstand0.current.value = score_understand;
                ref_scorePractice0.current.value = score_practice;

                setDetail_oral_scoreUnderstand0([...detail_oral_understand]);
                setDetail_prev_oral_scoreUnderstand0([...detail_oral_understand_prev]);
                setDetail_oral_scorePractice0([...detail_oral_practice]);
                setDetail_prev_oral_scorePractice0([...detail_oral_practice_prev]);

                setDetail_ons_scoreUnderstand0([...detail_ons_understand]);
                setDetail_prev_ons_scoreUnderstand0([...detail_ons_understand_prev]);
                setDetail_ons_scorePractice0([...detail_ons_practice]);
                setDetail_prev_ons_scorePractice0([...detail_ons_practice_prev]);

                //auto_translate_behav_score();
                setIsBehaveScore_show0(false);
            }}/>

            <ModalBehavScore 
            isShow={is_BehaveScore_show1} 
            items_oral={default_behave_items_oral}
            items_ons={default_behave_items_ons}
            day={1}
            onCancel={() => setIsBehaveScore_show1(false)}
            onSubmit={(oral, ons, 
                score_understand, score_understand_prev, score_practice, score_practice_prev, 
                detail_oral_understand, detail_oral_understand_prev, detail_oral_practice, detail_oral_practice_prev,
                detail_ons_understand, detail_ons_understand_prev, detail_ons_practice, detail_ons_practice_prev) => {
                    
                if(oral && ons) {
                    ref_foodType1.current.selectedIndex = 0;
                }
                else {
                    ref_foodType1.current.selectedIndex = (oral) ? 1 : 2;
                }

                ref_prev_scoreUnderstand1.current.value = score_understand_prev;
                ref_prev_scorePractice1.current.value = score_practice_prev;
                ref_scoreUnderstand1.current.value = score_understand;
                ref_scorePractice1.current.value = score_practice;

                setDetail_oral_scoreUnderstand1([...detail_oral_understand]);
                setDetail_prev_oral_scoreUnderstand1([...detail_oral_understand_prev]);
                setDetail_oral_scorePractice1([...detail_oral_practice]);
                setDetail_prev_oral_scorePractice1([...detail_oral_practice_prev]);

                setDetail_ons_scoreUnderstand1([...detail_ons_understand]);
                setDetail_prev_ons_scoreUnderstand1([...detail_ons_understand_prev]);
                setDetail_ons_scorePractice1([...detail_ons_practice]);
                setDetail_prev_ons_scorePractice1([...detail_ons_practice_prev]);

                //auto_translate_behav_score();
                setIsBehaveScore_show1(false);
            }}/>

            <ModalBehavScore 
            isShow={is_BehaveScore_show2}
            items_oral={default_behave_items_oral}
            items_ons={default_behave_items_ons}
            day={2}
            onCancel={() => setIsBehaveScore_show2(false)}
            onSubmit={(oral, ons, 
                score_understand, score_understand_prev, score_practice, score_practice_prev, 
                detail_oral_understand, detail_oral_understand_prev, detail_oral_practice, detail_oral_practice_prev,
                detail_ons_understand, detail_ons_understand_prev, detail_ons_practice, detail_ons_practice_prev) => {
                
                if(oral && ons) {
                    ref_foodType2.current.selectedIndex = 0;
                }
                else {
                    ref_foodType2.current.selectedIndex = (oral) ? 1 : 2;
                }

                ref_prev_scoreUnderstand2.current.value = score_understand_prev;
                ref_prev_scorePractice2.current.value = score_practice_prev;
                ref_scoreUnderstand2.current.value = score_understand;
                ref_scorePractice2.current.value = score_practice;

                setDetail_oral_scoreUnderstand2([...detail_oral_understand]);
                setDetail_prev_oral_scoreUnderstand2([...detail_oral_understand_prev]);
                setDetail_oral_scorePractice2([...detail_oral_practice]);
                setDetail_prev_oral_scorePractice2([...detail_oral_practice_prev]);

                setDetail_ons_scoreUnderstand2([...detail_ons_understand]);
                setDetail_prev_ons_scoreUnderstand2([...detail_ons_understand_prev]);
                setDetail_ons_scorePractice2([...detail_ons_practice]);
                setDetail_prev_ons_scorePractice2([...detail_ons_practice_prev]);

                //auto_translate_behav_score();
                setIsBehaveScore_show2(false);
            }}/>
        </>
    )
}

const CCPC_Follow_up = ({handle_onchange}) => {
    const [ continue_followup, setContinue_followup ] = useState(false);
    const ref_date = useRef(null);

    useEffect(() => {
        if(!continue_followup) {
            if(ref_date.current) {
                ref_date.current.value = '';
            }
        }
    }, [continue_followup]);

    return (
        <>
            <p className='notoser-reg text-web-black my-4'>การติดตาม</p>
            <select id='F/U' name='F/U' 
            className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4'
            onChange={(e) => {
                setContinue_followup(e.target.value === '1');
                handle_onchange();
            }}>
                <option value="ไม่ระบุ">โปรดระบุแผนการติดตาม</option>
                <option value="0">ไม่ติดตาม (0)</option>
                <option value="1">ติดตาม (1)</option>
            </select>

            <p className='notoser-reg text-web-black my-4'>วัน F/U (กรณีติดตามต่อ)</p>
            <input ref={ref_date} type='date' id='วัน_F/U' name='วัน_F/U' disabled={!continue_followup} onChange={handle_onchange}
            className={'border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (continue_followup ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')}></input>
        </>
    )
}

const CCPC_SOC = ({handle_onchange}) => {
    const ref_SOC0 = useRef(null);
    const ref_SOC1 = useRef(null);
    const ref_SOC2 = useRef(null);
    const ref_auto = useRef(null);
    const [ auto_translate, setAuto_translate ] = useState(false);
    const [ auto_translate_label, setAuto_translate_label ] = useState('');

    function auto_translate_SOC() {
        ref_auto.current.value = "ไม่ระบุ";
        setAuto_translate(false);
        setAuto_translate_label('');

        if(ref_SOC0.current.value !== 'ไม่ระบุ' || ref_SOC1.current.value !== 'ไม่ระบุ' || ref_SOC2.current.value !== 'ไม่ระบุ') {
            ref_auto.current.value = "N/A";
            setAuto_translate(true);
        }

        //if IPD is empty, end function
        if(ref_SOC0.current) {
            if(ref_SOC0.current.value === 'ไม่ระบุ') {
                setAuto_translate_label('IPD : ');
                return;
            }
        }

        //after this line, IPD cannot be empty
        if(ref_SOC1.current) {
            //if OPD1 is empty, end function
            if(ref_SOC1.current.value === 'ไม่ระบุ') {
                return;
            }

            //else if there are both IPD and OPD1
            const score0 = parseInt(ref_SOC0.current.value);
            const score1 = parseInt(ref_SOC1.current.value);

            if(score1 >= score0) {
                ref_auto.current.value = "1";
                setAuto_translate_label('OPD1 : ');
                setAuto_translate(true);
            }
            else {
                ref_auto.current.value = "0";
                setAuto_translate_label('OPD1 : ');
                setAuto_translate(true);
            }
        }

        if(ref_SOC2.current) {
            //if OPD2 is empty, end function
            if(ref_SOC2.current.value === 'ไม่ระบุ') {
                return;
            }

            //else if there are both IPD and OPD2
            const score0 = parseInt(ref_SOC0.current.value);
            const score2 = parseInt(ref_SOC2.current.value);

            if(score2 >= score0) {
                ref_auto.current.value = "1";
                setAuto_translate_label('OPD2 : ');
                setAuto_translate(true);
            }
            else {
                ref_auto.current.value = "0";
                setAuto_translate_label('OPD2 : ');
                setAuto_translate(true);
            }
        }
    }

    return (
        <>
            <h3 className='notoser-bold text-2xl text-orange-primary mt-16 mb-10 text-center sm:text-start'>Stage of Change</h3>
            <Swiper
            className='w-full h-fit'
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                1050: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                680: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            }}
            modules={[Pagination]}
            >
                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินที่ IPD</p>
                    <input type='date' id='SOC_IPD_Date' name='SOC_IPD_Date' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                    
                    <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร</p>
                    <select ref={ref_SOC0} id='SOC_IPD' name='SOC_IPD' 
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'
                    onChange={() => {
                        handle_onchange();
                        auto_translate_SOC();
                    }}>
                        <option value="ไม่ระบุ">โปรดระบุ Stage of change</option>
                        <option value="0">Precontemplation (0)</option>
                        <option value="1">Contemplation (1)</option>
                        <option value="2">Preparation (2)</option>
                        <option value="3">Action (3)</option>
                        <option value="4">Maintenance (4)</option>
                    </select>
                </SwiperSlide>

                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 1</p>
                    <input type='date' id='SOC_OPD1_Date' name='SOC_OPD1_Date' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                    
                    <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร</p>
                    <select ref={ref_SOC1} id='SOC_OPD1' name='SOC_OPD1' 
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'
                    onChange={() => {
                        handle_onchange();
                        auto_translate_SOC();
                    }}>
                        <option value="ไม่ระบุ">โปรดระบุ Stage of change</option>
                        <option value="0">Precontemplation (0)</option>
                        <option value="1">Contemplation (1)</option>
                        <option value="2">Preparation (2)</option>
                        <option value="3">Action (3)</option>
                        <option value="4">Maintenance (4)</option>
                    </select>
                </SwiperSlide>

                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 2</p>
                    <input type='date' id='SOC_OPD2_Date' name='SOC_OPD2_Date' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                    
                    <p className='notoser-reg text-web-black mt-4 mb-2'>ลักษณะอาหาร</p>
                    <select ref={ref_SOC2} id='SOC_OPD2' name='SOC_OPD2' 
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'
                    onChange={() => {
                        handle_onchange();
                        auto_translate_SOC();
                    }}>
                        <option value="ไม่ระบุ">โปรดระบุ Stage of change</option>
                        <option value="0">Precontemplation (0)</option>
                        <option value="1">Contemplation (1)</option>
                        <option value="2">Preparation (2)</option>
                        <option value="3">Action (3)</option>
                        <option value="4">Maintenance (4)</option>
                    </select>
                </SwiperSlide>
            </Swiper>

            <p className='notoser-reg text-web-black my-4'>การแปลผล Stage of Change (แปลผลอัตโนมัติ)</p>
            <select id='SOC_ผ่าน' name='SOC_ผ่าน' ref={ref_auto} disabled={true}
            className={'border focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4 cursor-not-allowed ' + (auto_translate ? 'bg-green-100 border-green-500' : 'bg-white border-gray-200')}>
                <option value="ไม่ระบุ">โปรดระบุการแปรผล</option>
                <option value="N/A">{auto_translate_label} N/A</option>
                <option value="0">{auto_translate_label} ไม่ผ่าน (0)</option>
                <option value="1">{auto_translate_label} ผ่าน (1)</option>
            </select>
        </>
    )
}

const CCPC_LDL = ({handle_onchange}) => {
    const ref_LDL0 = useRef(null);
    const ref_LDL1 = useRef(null);
    const ref_LDL2 = useRef(null);
    const ref_auto = useRef(null);
    const [ auto_translate, setAuto_translate ] = useState(false);
    const [ auto_translate_label, setAuto_translate_label ] = useState('');

    function auto_translate_LDL() {
        ref_auto.current.value = "ไม่ระบุ";
        setAuto_translate(false);
        setAuto_translate_label('');

        if(ref_LDL0.current.value !== 'ไม่ระบุ' || ref_LDL1.current.value !== 'ไม่ระบุ' || ref_LDL2.current.value !== 'ไม่ระบุ') {
            ref_auto.current.value = "N/A";
            setAuto_translate(true);
        }

        //if IPD is empty, end function
        if(ref_LDL0.current) {
            if(ref_LDL0.current.value === '') {
                return;
            }
        }

        //after this line, IPD cannot be empty
        if(ref_LDL1.current) {
            //if OPD1 is empty, end function
            if(ref_LDL1.current.value === '') {
                return;
            }

            //else if there are both IPD and OPD1
            const score0 = parseInt(ref_LDL0.current.value);
            const score1 = parseInt(ref_LDL1.current.value);

            if(score0 > score1 || score1 < 70) {
                ref_auto.current.value = "1";
                setAuto_translate_label('OPD1 : ');
                setAuto_translate(true);
            }
            else {
                ref_auto.current.value = "0";
                setAuto_translate_label('OPD1 : ');
                setAuto_translate(true);
            }
        }

        if(ref_LDL2.current) {
            //if OPD2 is empty, end function
            if(ref_LDL2.current.value === '') {
                return;
            }

            //else if there are both IPD and OPD2
            const score0 = parseInt(ref_LDL0.current.value);
            const score2 = parseInt(ref_LDL2.current.value);

            if(score0 > score2 || score2 < 70) {
                ref_auto.current.value = "1";
                setAuto_translate_label('OPD2 : ');
                setAuto_translate(true);
            }
            else {
                ref_auto.current.value = "0";
                setAuto_translate_label('OPD2 : ');
                setAuto_translate(true);
            }
        }
    }

    return (
        <>
            <h3 className='notoser-bold text-2xl text-orange-primary mt-16 mb-10 text-center sm:text-start'>ผลการตรวจระดับ LDL (เฉพาะ ACS, Stroke)</h3>
            <Swiper
            className='w-full h-fit'
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                1050: {
                    slidesPerView: 3,
                    spaceBetween: 50,
                },
                680: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
            }}
            modules={[Pagination]}
            >
                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินก่อน consult</p>
                    <input type='date' id='LDL_IPD_Date' name='LDL_IPD_Date' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                    
                    <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ LDL (mmol/L)</p>
                    <input type='number' id='LDL_IPD' name='LDL_IPD' ref={ref_LDL0}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='โปรดระบุระดับ LDL (mmol/L)'
                    onChange={() => {
                        auto_translate_LDL();
                        handle_onchange();
                    }}></input>
                </SwiperSlide>

                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 1</p>
                    <input type='date' id='LDL_OPD1_Date' name='LDL_OPD1_Date' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                    
                    <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ LDL (mmol/L)</p>
                    <input type='number' id='LDL_OPD1' name='LDL_OPD1' ref={ref_LDL1}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='โปรดระบุระดับ LDL (mmol/L)'
                    onChange={() => {
                        auto_translate_LDL();
                        handle_onchange();
                    }}></input>
                </SwiperSlide>

                <SwiperSlide className='w-full h-fit border border-gray-300 rounded-md px-6 py-6 cursor-grab'>
                    <p className='notoser-reg text-web-black mb-4'>การประเมินหลัง consult ครั้งที่ 2</p>
                    <input type='date' id='LDL_OPD2_Date' name='LDL_OPD2_Date' onChange={handle_onchange}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4'></input>
                    
                    <p className='notoser-reg text-web-black mt-4 mb-2'>ผลตรวจระดับ LDL (mmol/L)</p>
                    <input type='number' id='LDL_OPD2' name='LDL_OPD2' ref={ref_LDL2}
                    className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-r-md w-full h-10 px-4' placeholder='โปรดระบุระดับ LDL (mmol/L)'
                    onChange={() => {
                        auto_translate_LDL();
                        handle_onchange();
                    }}></input>
                </SwiperSlide>
            </Swiper>

            <p className='notoser-reg text-web-black my-4'>การแปลผล LDL (แปลผลอัตโนมัติ)</p>
            <select id='LDL_ผ่าน' name='LDL_ผ่าน' ref={ref_auto} disabled={true}
            className={'border focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 mb-4 cursor-not-allowed ' + (auto_translate ? 'bg-green-100 border-green-500' : 'bg-white border-gray-200')}>
                <option value="ไม่ระบุ">โปรดระบุการแปรผล</option>
                <option value="N/A">{auto_translate_label} N/A</option>
                <option value="0">{auto_translate_label} ไม่ผ่าน (0)</option>
                <option value="1">{auto_translate_label} ผ่าน (1)</option>
            </select>
        </>
    )
}

const CCPC_Diagnosis = ({handle_onchange}) => {
    const [ diagnosis_is_other, setDiagnosis_is_other ] = useState(false);

    return (
        <div className='flex flex-col lg:flex-row justify-start gap-3'>
            <div className='w-full lg:w-96 h-fit'>
                <p className='notoser-reg text-web-black mt-4 mb-2'>Nutritional Diagnosis</p>
                <select id='ccpc_select_diagnosis' name='ccpc_select_diagnosis' className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full lg:w-fit h-10 px-2'
                onChange={(e) => {
                    document.getElementById('CCPC_diagnosis').value = '';
                    setDiagnosis_is_other(e.target.value === 'อื่นๆ');
                    handle_onchange();
                }}>
                    <option value="ไม่ระบุ">ไม่ระบุ</option>
                    <option value="Inadequate energy intake">01 Inadequate energy intake</option>
                    <option value="Excessive energy intake">02 Excessive energy intake</option>
                    <option value="Inadequate oral intake">03 Inadequate oral intake</option>
                    <option value="Excessive oral intake">04 Excessive oral intake</option>
                    <option value="Inadequate fat intake">05 Inadequate fat intake</option>
                    <option value="Excessive fat intake">06 Excessive fat intake</option>
                    <option value="Inadequate protein intake">07 Inadequate protein intake</option>
                    <option value="Excessive protein intake">08 Excessive protein intake</option>
                    <option value="Inadequate carbohydrate intake">09 Inadequate carbohydrate intake</option>
                    <option value="Excessive carbohydrate intake">10 Excessive carbohydrate intake</option>
                    <option value="Inadequate sodium intake">11 Inadequate sodium intake</option>
                    <option value="Inadequate potassium intake">12 Inadequate potassium intake</option>
                    <option value="Inadequate phosphorus intake">13 Inadequate phosphorus intake</option>
                    <option value="Excessive sodium intake">14 Excessive sodium intake</option>
                    <option value="Excessive potassium intake">15 Excessive potassium intake</option>
                    <option value="Excessive phosphorus intake">16 Excessive phosphorus intake</option>
                    <option value="Underweight">17 Underweight</option>
                    <option value="Unintended weight loss">18 Unintended weight loss</option>
                    <option value="Overweight/Obesity">19 Overweight/Obesity</option>
                    <option value="Food and nutrition related knowledge deficit">20 Food and nutrition related knowledge deficit</option>
                    <option value="Not ready for diet/lifestyle change">21 Not ready for diet/lifestyle change</option>
                    <option value="No nutrition diagnosis at this time">22 No nutrition diagnosis at this time</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                </select>
            </div>

            <div className='grow h-fit'>
                <p className='notoser-reg text-web-black mt-0 lg:mt-4 mb-2'>Nutritional Diagnosis อื่นๆ (กรณีที่ไม่มีในตัวเลือก)</p>
                <input type='text' id='CCPC_diagnosis' name='CCPC_diagnosis' disabled={!diagnosis_is_other} onChange={handle_onchange}
                className={'border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full h-10 px-4 ' + (diagnosis_is_other ? 'bg-white' : 'bg-gray-300 cursor-not-allowed')} 
                placeholder='Nutritional Diagnosis อื่นๆ (กรณีที่ไม่มีในตัวเลือก)'></input>
            </div>
        </div>
    )
}

const Form_Accordion_CCPC = ({handle_onchange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState('0px');
    const contentRef = useRef(null);

    useEffect(() => {
        setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }, [isOpen]);

    return (
        <>
            <div className='my-4'>
                <button type='button' id='accordion_ccpc_header' className={"w-full px-8 py-4 text-left flex justify-between items-center bg-white " + (isOpen ? 'rounded-t-md' : 'rounded-md')} onClick={() => setIsOpen(!isOpen)}>
                    <h3 className='notoser-bold text-2xl text-orange-primary text-center sm:text-start'>1&#41; กลุ่มโรค CCPC</h3>
                </button>

                <div ref={contentRef} className="overflow-hidden rounded-b-md transition-all duration-300 ease-in-out" style={{ maxHeight: height }}>
                    <div className='pt-8 pb-16 px-8 bg-white'>
                        <CCPC_Medical_conditions handle_onchange={handle_onchange}/>
                        <CCPC_Nutrition_care_process handle_onchange={handle_onchange}/>
                        <CCPC_Behavior_score handle_onchange={handle_onchange}/>
                        <CCPC_Follow_up handle_onchange={handle_onchange}/>
                        <CCPC_SOC handle_onchange={handle_onchange}/>
                        <CCPC_LDL handle_onchange={handle_onchange}/>
                        <CCPC_Diagnosis handle_onchange={handle_onchange}/>

                        <p className='notoser-reg text-web-black my-4'>เป้าหมายการให้โภชนบำบัด</p>
                        <textarea id='CCPC_goal' name='CCPC_goal' rows={5} placeholder='โปรดระบุเป้าหมายการให้โภชนบำบัด' onChange={handle_onchange}
                        className='bg-white border border-gray-200 focus:border-orange-bold focus:outline-orange-primary rounded-md w-full py-3 px-3'></textarea>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Form_Accordion_CCPC