import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Staff_login from './pages/Staff_login/Staff_login';
import Staff_dashboard from './pages/Staff_login/Staff_dashboard';
import Form_OPD from './pages/Components/Form/Form_OPD/Form_OPD';
import Form_OPD_dashboard from './pages/Components/Form/Form_OPD/Form_OPD_dashboard';

import 'boxicons';
import Form_NutritionIntake from './pages/Components/Form/Form_NutritionIntake/Form_NutritionIntake';
import ScoreLINE from './pages/LINE/ScoreLINE';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><p className='text-orange-prymary'>นี่คือเว็บไซต์ของโรงพยาบาลพญาไท2</p></div>,
  },
  {
    path: "/staff",
    element: <Staff_login/>,
  },
  {
    path: "/staff/dashboard/:name?",
    element: <Staff_dashboard/>,
  },
  {
    path: "/staff/form/opd/:name?",
    element: <Form_OPD_dashboard/>,
  },
  {
    path: "/staff/form/opd/add/:name?/:id?",
    element: <Form_OPD/>,
  },
  {
    path: "/staff/form/nutritionintake/:name?/:id?",
    element: <Form_NutritionIntake/>,
  },
  {
    path: "/scoreline/:LINEID?",
    element: <ScoreLINE/>,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
