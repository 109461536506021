import React, { useEffect, useRef, useState } from 'react';
import '../../pages/Staff_login/staff_login.css';
import ModalWaiting from '../Components/Modal/ModalWaiting/ModalWaiting';
import { useParams } from 'react-router-dom';

const Star = ({this_score, hover_score, user_score}) => {
    const [ score, setScore ] = useState(0);

    useEffect(() => {
        setScore((hover_score === 0) ? user_score : hover_score);
    }, [hover_score, user_score])

    return (
        <box-icon name='star' type='solid' color={(score >= this_score) ? '#fe9d6a' : '#fdf0d6'}></box-icon>
    )
}

const Loading_Spiner_White = () => {
    return (
        <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
        </div>
    )
}

const Loading_Spiner_Orange = ({submit_success}) => {
    return (
        <div role="status" className={submit_success ? 'invisible' : 'visible'}>
            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-orange-primary" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
        </div>
    )
}

const ScoreLINE = () => {
    const [ score, setScore ] = useState(0);
    const [ hover_score, setHover_score ] = useState(0);
    const [ active_submit, setActive_submit ] = useState(false);
    const [ submit_loading, setSubmit_loading ] = useState(false);
    const [ submit_success, setSubmit_success ] = useState(false);
    const ref_comment = useRef(null);
    const ref_score = useRef(null);
    const ref_lineID = useRef(null);

    const { LINEID } = useParams();

    useEffect(() => {
        if(ref_score.current) {
            ref_score.current.value = score;
        }

        setActive_submit(score !== 0);
    }, [score]);

    const submit_form = async () => {
        setSubmit_loading(true);
        
        if(!submit_loading) {
            await PrepareData();
            let sending_main_form_success = await SendMainForm();

            if(!sending_main_form_success) {
                alert("ส่งข้อมูลไม่สำเร็จ โปรดลองใหม่ในภายหลัง");
                return;
            }

            await Handle_submit_success();
        }

        function Handle_submit_success() {
            return new Promise((resolve) => {
                setSubmit_success(true);
                alert('ส่งข้อมูลสำเร็จ ขอบคุณสำหรับความเห็นของท่าน');
                resolve();
            });
        }

        function PrepareData() {
            return new Promise((resolve) => {
                if(ref_lineID.current) {
                    ref_lineID.current.value = LINEID;
                }

                resolve();
            });
        }

        function SendMainForm() {
            return new Promise((resolve) => {
                const scriptURL = 'https://script.google.com/macros/s/AKfycbxvY20rqxSeEFu46koIWy__cRCRBfXj3dV6_4xmGNkxiO9ad3F7fAuUeOXx9h1rH2kY/exec';
                const form = document.forms['google-sheet'];

                fetch(scriptURL, { method: 'POST', body: new FormData(form)})
                .then(res => res.json())
                .then(res => {
                    console.log(res);
                    resolve(true);
                })
                .catch(err => {
                    console.log(err);
                    resolve(false);
                });
            });
        }
    }

    return (
        <>
            <div className='relative staff_login_container'>
                { /* Content */ }
                <div className='relative w-full h-full flex justify-center items-center'>
                    <form id="google-sheet" className='w-11/12 sm:w-fit h-fit px-6 sm:px-10 py-14 bg-white rounded-lg shadow-md drop-shadow-md mt-8 z-10'>
                        <div className='relative w-full h-fit z-10'>
                            <h3 className='notoser-bold text-lg text-center text-web-brown mt-4 mb-2'>โปรดให้คะแนนความพึงพอใจ</h3>

                            <div className='w-full h-fit flex justify-center gap-1 mt-6 mb-2'>
                                <p className='notoser-bold text-4xl text-web-black'>{(hover_score === 0) ? score : hover_score}</p>
                                <p className='notoser-reg text-xs text-web-black'>/ 5 คะแนน</p>
                            </div>
                            
                            <div className='w-full h-fit flex justify-center gap-6'>
                                <div className='w-10 h-fit cursor-pointer flex flex-col justify-center items-center'
                                onMouseEnter={() => setHover_score(1)} onMouseLeave={() => setHover_score(0)}
                                onClick={() => setScore(1)}>
                                    <Star this_score={1} hover_score={hover_score} user_score={score}/>
                                </div>

                                <div className='w-10 h-fit cursor-pointer flex flex-col justify-center items-center'
                                onMouseEnter={() => setHover_score(2)} onMouseLeave={() => setHover_score(0)}
                                onClick={() => setScore(2)}>
                                    <Star this_score={2} hover_score={hover_score} user_score={score}/>
                                </div>

                                <div className='w-10 h-fit cursor-pointer flex flex-col justify-center items-center'
                                onMouseEnter={() => setHover_score(3)} onMouseLeave={() => setHover_score(0)}
                                onClick={() => setScore(3)}>
                                    <Star this_score={3} hover_score={hover_score} user_score={score}/>
                                </div>

                                <div className='w-10 h-fit cursor-pointer flex flex-col justify-center items-center'
                                onMouseEnter={() => setHover_score(4)} onMouseLeave={() => setHover_score(0)}
                                onClick={() => setScore(4)}>
                                    <Star this_score={4} hover_score={hover_score} user_score={score}/>
                                </div>

                                <div className='w-10 h-fit cursor-pointer flex flex-col justify-center items-center'
                                onMouseEnter={() => setHover_score(5)} onMouseLeave={() => setHover_score(0)}
                                onClick={() => setScore(5)}>
                                    <Star this_score={5} hover_score={hover_score} user_score={score}/>
                                </div>
                            </div>

                            <div className='w-full h-fit flex justify-center gap-6 mt-2'>
                                <div className='w-full h-fit cursor-pointer flex flex-col col-span-2 justify-center items-center'>
                                    <p className='notoser-reg text-xs text-web-black text-center mt-2'>ไม่ชอบเลย</p>
                                </div>

                                <div className='w-10 h-fit cursor-pointer flex flex-col justify-center items-center invisible'></div>

                                <div className='w-full h-fit cursor-pointer flex flex-col col-span-2 justify-center items-center'>
                                    <p className='notoser-reg text-xs text-web-black text-center mt-2'>ชอบมาก</p>
                                </div>
                            </div>

                            <hr className='w-3/4 h-fit ml-auto mr-auto my-4'/>

                            <div className='w-full h-fit my-8'>
                                <p className='notoser-reg text-web-black text-sm'>ความคิดเห็นเพิ่มเติม (ถ้ามี)</p>
                                <textarea ref={ref_comment} name='comment'
                                className='w-full border border-gray-200 rounded-md mt-4 p-2 outline-orange-primary placeholder:notoser-reg placeholder:text-sm' 
                                rows="5" placeholder='ความคิดเห็นเพิ่มเติม (ถ้ามี)'/>
                            </div>

                            <input ref={ref_score} name='score' className='hidden'></input>
                            <input ref={ref_lineID} name='line_id' className='hidden'></input>

                            <div className={'absolute top-0 left-0 w-full h-full flex justify-center items-center bg-white transition-all duration-200 ' + (submit_loading ? 'visible z-20 opacity-80' : 'invisible z-0 opacity-0 translate-y-6')}>
                                <Loading_Spiner_Orange submit_success={submit_success}/>
                            </div>
                        </div>

                        <div className={'w-full h-fit ' + (submit_success ? 'hidden' : 'block')}>
                            <button type='button' className={'w-full h-fit py-4 flex gap-2 justify-center items-center rounded-md notoser-semi z-30 ' + (active_submit ? 'bg-orange-primary hover:bg-orange-bold text-white ' : 'bg-gray-300 text-gray-500 ') + ((active_submit && !submit_loading) ? 'cursor-pointer' : 'cursor-not-allowed')} onClick={submit_form}>
                                <div className={'scale-75 ' + (submit_loading ? 'block' : 'hidden')}>
                                    <Loading_Spiner_White/>
                                </div>

                                <span className={'notoser-semi text-white ' + (submit_loading ? 'hidden' : 'block')}>ส่งข้อมูล</span>
                                <span className={'notoser-semi text-white ' + (submit_loading ? 'block' : 'hidden')}>กำลังส่งข้อมูล</span>
                            </button>
                        </div>

                        <div className={'w-full h-fit ' + (submit_success ? 'block' : 'hidden')}>
                            <button type='button' className={'w-full h-fit py-4 flex gap-2 justify-center items-center rounded-md notoser-semi z-30 bg-orange-primary hover:bg-orange-bold text-white cursor-pointer'} onClick={() => window.location.reload()}>
                                ส่งข้อมูลอีกครั้ง
                            </button>
                        </div>
                    </form>
                </div>

                <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
            </div>
        </>
    )
}

export default ScoreLINE