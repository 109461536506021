import React, { useEffect, useState } from 'react';
import './staff_login.css';
import { useNavigate, useParams } from 'react-router-dom';
import ModalWaiting from '../Components/Modal/ModalWaiting/ModalWaiting';
import Staff_form_cover from './Staff_form_cover';

const Staff_dashboard = () => {
    const [ loading_screen, setLoading_screen ] = useState(false);

    const { name } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if(name === null || name === undefined) {
            navigate("/staff", { replace: true });
        }
    }, []);

    const handle_logout = () => {
        navigate("/staff", { replace: true });
    }

    return (
        <>
            <div className='relative staff_login_container'>
                <div className='absolute left-0 top-0 w-screen h-screen overflow-y-auto z-10'>
                    <div className='mx-8 my-8'>
                        <div className='w-full h-fit px-8 md:px-12 py-14 bg-white rounded-lg shadow-md drop-shadow-md z-10'>
                            <h3 className='notoser-semi text-2xl text-orange-primary my-6 text-center sm:text-start'>ยินดีต้อนรับ</h3>
                            <p className='notoser-reg text-center sm:text-start'>{name}</p>
                            <div className='w-full h-fit flex justify-center sm:justify-start'>
                                <button className='block sm:hidden border border-red-500 px-4 pt-3 pb-1 rounded-md text-red-500 hover:bg-red-500 hover:text-white mt-4' onClick={handle_logout}>ออกจากระบบ</button>
                            </div>

                            <div className='flex flex-col sm:flex-row gap-5 sm:gap-1 sm:gap-6 my-6'>
                                <Staff_form_cover cover_url={'https://img.freepik.com/free-photo/assortment-vaccination-elements-covid19_23-2148880534.jpg?t=st=1718288680~exp=1718292280~hmac=579de66382d5cef423dceaf7a85333d10098264937a40ddc687a06826705b3ec&w=1060'} title={'แบบฟอร์มบันทึกสถิติ OPD'} url_destination={'/staff/form/opd/' + name}/>
                                <Staff_form_cover cover_url={'https://www.cleaneatingkitchen.com/wp-content/uploads/2023/02/Thai-Food-Vertical-Hero.jpg'} title={'แบบประเมิน 1'} url_destination={'/staff/dashboard/form/food/'}/>
                                <Staff_form_cover cover_url={'https://qph.cf2.quoracdn.net/main-qimg-ec3273dbf9918e1c4e7334d02f51a97c-lq'} title={'แบบประเมิน 2'} url_destination={'/staff/dashboard/form/bev/'}/>
                                <Staff_form_cover cover_url={'https://www.mpulse.de/-/media/project/mag/corporate/mpulse/2022/company-canteens-from-crisis-management-to-flexoffice/kantine_45.jpg?rev=0f8a4eeb-ce8e-4fca-95ea-901988a4b6c1'} title={'แบบประเมิน 3'} url_destination={'/staff/dashboard/form/overall/'}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='absolute left-0 top-0 w-full h-full bg-black opacity-80 z-0'></div>
            </div>
            
            { /* Backdrop screen for loading */ }
            <ModalWaiting isShow={loading_screen}/>
        </>
    )
}

export default Staff_dashboard